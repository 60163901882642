import httpService from "../../interceptors/http";

export const postBillService = async ({ billData, userId }) => {
  return httpService().post(`/bill`, { ...billData, userId });
};

export const getBillService = async ({ userId }) => {
  return httpService().get(`/bill`, {
    params: {
      userId,
    },
  });
};

export const getBillDetailService = async ({ billId, userId }) => {
  return httpService().get(`/bill/${billId}`, {
    params: { userId },
  });
};

export const postUploadFile = async (data, userId) => {
  return httpService().post(`/bill/upload-invoice`, {
    data,
    userId,
  });
};

export const postDeleteFile = async (fileName, userId) => {
  return httpService().post(`/bill/delete-invoice`, {
    fileName,
    userId,
  });
};
