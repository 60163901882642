import httpService from "../../interceptors/http";
// import { getJwtUser } from "utils/jwt-utils";
// const userId = getJwtUser();

export const postSignInUser = async ({ email }) => {
  return httpService().post(`/user/signIn`, { email });
};

export const postVerifyOtp = async ({ email, otp, accessToken }) => {
  return httpService().post(`/user/verifyOtp`, { email, otp, accessToken });
};

export const postProfile = async ({ profileData, userId }) => {
  return httpService().put(`/user/${userId}`, { ...profileData, userId });
};

export const getProfile = async ({ userId }) => {
  return httpService().get(`/user`, {
    params: {
      userId,
    },
  });
};
