import React from "react";
import { Tabs, TabList, TabPanels, TabPanel } from "@chakra-ui/react";
import { Box, Card, Tab } from "@chakra-ui/react";

import AccountDetails from "./components/AccountDetails";
import BusinessDetails from "./components/BusinessDetails";
import useProfileStore from "store/profile";

const Account = () => {
  const { profileState } = useProfileStore((state) => state);
  const data = profileState?.get?.success?.data || {};
  return (
    <Box m="auto" pt={{ base: "130px", md: "80px", xl: "80px" }} width="95%">
      <Card p={10} borderRadius="20px" bgColor={"#F7FAFC"}>
        <Tabs>
          <TabList color={"#2b6cb0"}>
            <Tab fontWeight={600} letterSpacing={0.5}>
              Profile
            </Tab>
            {data.accountType === "business" && (
              <Tab fontWeight={600} letterSpacing={0.5}>
                Business
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel>
              <AccountDetails />
            </TabPanel>
            {data.accountType === "business" && (
              <TabPanel>
                <BusinessDetails />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default Account;
