import create from "zustand";
import produce from "immer";
import { postVerifyOtp } from "../../service/sign-in-service";
import { setAuthTokens } from "axios-jwt";

const INITIAL_OTP_STATE = {
  post: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useOtpStore = create((set, get) => ({
  otpState: INITIAL_OTP_STATE,
  postOtpAction: async ({ email, otp, accessToken }) => {
    set(
      produce((state) => {
        state.otpState.post.loading = true;
      })
    );

    try {
      const data = await postVerifyOtp({ email, otp, accessToken });
      setAuthTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      });
      set(
        produce((state) => {
          state.otpState.post.loading = false;
          state.otpState.post.success.ok = true;
          state.otpState.post.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.otpState.post.loading = false;
          state.otpState.post.failure.error = true;
          state.otpState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
}));

export default useOtpStore;
