import create from "zustand";
import produce from "immer";
import { postSignInUser } from "../../service/sign-in-service";

const INITIAL_SIGN_IN_STATE = {
  post: {
    loading: false,
    email: "",
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useSignInStore = create((set, get) => ({
  signInState: INITIAL_SIGN_IN_STATE,
  postSignInAction: async ({ email }) => {
    set(
      produce((state) => {
        state.signInState.post.loading = true;
        state.signInState.post.email = email;
      })
    );

    try {
      const data = await postSignInUser({ email });
      set(
        produce((state) => {
          state.signInState.post.loading = false;
          state.signInState.post.success.ok = true;
          state.signInState.post.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.signInState.post.loading = false;
          state.signInState.get.failure.error = true;
          state.signInState.get.failure.message = e.message;
        })
      );
      throw e;
    }
  },
}));

export default useSignInStore;
