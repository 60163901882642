import { getAccessToken } from "axios-jwt";

export const decodeJwtToken = (token) => {
  return JSON.parse(window.atob(token.split(".")[1]));
};

export const getJwtUser = () => {
  const token = typeof window !== "undefined" && getAccessToken();
  if (token) {
    const { userId } = JSON.parse(window.atob(token.split(".")[1]));
    return userId;
  } else {
    return null;
  }
};

export const isKybVerified = () => {
  const token = typeof window !== "undefined" && getAccessToken();
  if (token) {
    const { kybStatus = "pending" } = JSON.parse(window.atob(token.split(".")[1]));
    return kybStatus === "verified";
  } else {
    return null;
  }
};
