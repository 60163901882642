import axios from "axios";
import { clearAuthTokens } from "axios-jwt";
import { getDataFromSession } from "../../utils/session-storing-utils";
import { urlMap } from "../../utils/config";

const service = ({ jwtToken = "", store = null, multipart = false } = {}) => {
  const options = {};
  const path = urlMap[window.location.host];
  const SERVER_URL = path?.server;
  options.baseURL = SERVER_URL + "/api";

  const key =
    path.env === "local" ? "auth-tokens-development" : "auth-tokens-production";
  const token =
    localStorage.getItem(key) === null
      ? ""
      : JSON.parse(localStorage.getItem(key));

  if (multipart === true) {
    options.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token.accessToken}`,
      apiKey: getDataFromSession("connectionData", "apiKey") || 0,
    };
  } else {
    options.headers = {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token.accessToken}`,
      apiKey: getDataFromSession("connectionData", "apiKey") || 0,
    };
  }

  const instance = axios.create(options);

  instance.interceptors.response.use(
    (response) => {
      return response && response.data;
    },
    async function (error) {
      console.log("Interceptor Error ", error);
      const { response } = error;
      if (
        response &&
        response?.status === 401 &&
        response?.data?.message === "Unauthorized"
        // && response?.statusText === "Unauthorized" // TODO - statusText returns empty string ("") in development/sandbox/production.
      ) {
        clearAuthTokens();
        // Router.replace("/buy/sign-in");
      } else if (response && response?.status === 406) {
        // Router.replace("/buy/kyc-conditions");
      }
      return Promise.reject({
        status: response?.status,
        statusText: response?.statusText,
        message: response?.data?.message || "Internal server error.",
      });
    }
  );

  return instance;
};

export default service;
