import React, { useEffect } from "react";
import Select from "react-select";
import { startCase, isEmpty, unset } from "lodash";
import { Drawer, DrawerBody, DrawerHeader } from "@chakra-ui/react";
import { DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";

import DateRangePicker from "../../../../components/calendar/DateRangePicker";

const BillsFilter = (props) => {
  const { isOpen, onClose, setFilters, setDateRange, dateRange } = props;
  const {
    formValues,
    setFormValues,
    inputData,
    setInputData,
    filters,
    handleOnReset,
  } = props;

  useEffect(() => {
    setFormValues(filters);
  }, []);

  const handleOnChanges = (key, op, value) => {
    setInputData({
      ...inputData,
      [key]: value,
    });
    let newValue;
    if (Array.isArray(value)) newValue = value.map((data) => data.value);
    else newValue = value.trim();
    setFormValues({
      ...formValues,
      [key]: { [`$${op}`]: newValue },
    });
  };

  const handleOnClear = () => {
    onClose();
    handleOnReset();
  };

  const handleOnFilter = () => {
    Object.keys(formValues).map((key) => {
      if (isEmpty(Object.values(formValues[key]))) unset(formValues, key);
    });
    setFilters(formValues);
    onClose();
  };

  const onChangeDateRange = (start, end) => {
    setDateRange({ startDate: start, endDate: end });
    setFormValues({
      ...formValues,
      ["dateRange"]: { startDate: start, endDate: end },
    });
  };

  const statusOptions = [
    { label: startCase("pending"), value: "pending" },
    { label: startCase("success"), value: "success" },
    { label: startCase("failed"), value: "failed" },
    { label: startCase("cancelled"), value: "cancelled" },
  ];

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            style={{ color: "#4299E1", fontFamily: "arial" }}
            size={"sm"}
            fontWeight={600}
            letterSpacing={0.1}
          >
            Filters
          </DrawerHeader>
          <DrawerBody>
            <div style={{ margin: "12px 0" }}>
              <DateRangePicker
                width={"100%"}
                onChangeDateRange={onChangeDateRange}
                start={dateRange.startDate}
                end={dateRange.endDate}
              />
            </div>

            <div style={{ margin: "12px 0" }}>
              <Input
                placeholder="Bill ID"
                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                type="text"
                value={formValues.billId && Object.values(formValues.billId)}
                onChange={(e) =>
                  handleOnChanges("billId", "eq", e.target.value)
                }
              />
            </div>

            <div style={{ margin: "12px 0" }}>
              <Input
                placeholder="Buyer Id"
                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                type="text"
                value={
                  formValues.buyerId && Object.values(formValues.buyerId)[0]
                }
                onChange={(e) =>
                  handleOnChanges("buyerId", "eq", e.target.value)
                }
              />
            </div>

            <div style={{ margin: "12px 0" }}>
              <Input
                placeholder="Amount"
                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                type="number"
                value={
                  formValues.invoiceAmount &&
                  Object.values(formValues.invoiceAmount)[0]
                }
                onChange={(e) =>
                  handleOnChanges("invoiceAmount", "eq", e.target.value)
                }
              />
            </div>

            <div style={{ margin: "12px 0" }}>
              <Input
                placeholder="Currency"
                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                type="text"
                value={
                  formValues.invoiceCurrency &&
                  Object.values(formValues.invoiceCurrency)[0]
                }
                onChange={(e) =>
                  handleOnChanges("invoiceCurrency", "eq", e.target.value)
                }
              />
            </div>

            <div style={{ margin: "12px 0" }}>
              <Select
                isMulti
                isSearchable={false}
                placeholder="Status"
                options={statusOptions}
                value={inputData.status}
                onChange={(e) => handleOnChanges("status", "in", e)}
              />
            </div>

            <div style={{ marginTop: "30px" }}>
              <Button
                onClick={() => handleOnFilter()}
                variant={"outline"}
                colorScheme="#422AFB"
                style={{ color: "#422AFB" }}
              >
                Filter
              </Button>
              <Button
                onClick={() => handleOnClear()}
                variant={"outline"}
                colorScheme="red"
                style={{ marginLeft: "30px" }}
              >
                Clear
              </Button>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BillsFilter;
