import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Button, chakra, Flex, FormControl, Box } from "@chakra-ui/react";
import { FormLabel, Select, Input, useToast } from "@chakra-ui/react";
import { FormErrorMessage } from "@chakra-ui/react";
import sc from "states-cities-db";

import useBusinessDetailStore from "store/business";
import { getJwtUser } from "utils/jwt-utils";

const BusinessForm = () => {
  const userId = getJwtUser();
  const { postBusinessDetailAction } = useBusinessDetailStore((state) => state);
  const { businessDetailState } = useBusinessDetailStore((state) => state);

  const countriesList = sc.getCountries();
  const toast = useToast();
  const history = useHistory();

  const onSubmitHandler = async (data) => {
    try {
      const responseData = await postBusinessDetailAction(data);
      if (responseData) {
        toast({
          title: "Business added successfully!!",
          position: "top",
          status: "success",
          duration: 3000,
        });
        setTimeout(() => {
          history.push("/admin/default", { replace: true });
        }, 1000);
      } else {
        toast({
          title: "Failed to add Business",
          position: "top",
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        position: "top",
        duration: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      incorporationNo: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      createdBy: userId,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Business Name Needed"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      zipCode: Yup.string().required("Zipcode is required"),
    }),
    onSubmit: (data) => {
      if (data) onSubmitHandler(data);
    },
  });

  const formInputStyles = {
    fontSize: "sm",
    fontWeight: "500",
    size: "lg",
  };

  const formLabelStyles = {
    display: "flex",
    ms: "4px",
    fontSize: "sm",
    fontWeight: "500",
    mb: "7px",
  };

  return (
    <Box>
      <chakra.form onSubmit={formik.handleSubmit}>
        <Flex direction="column" gap={5}>
          <FormControl
            isRequired
            isInvalid={formik.errors.name && formik.touched.name}
          >
            <FormLabel {...formLabelStyles}>Business Name</FormLabel>
            <Input
              type="text"
              name="name"
              placeholder="Business Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              {...formInputStyles}
            />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel {...formLabelStyles}>Incorporation Number</FormLabel>
            <Input
              type="number"
              name="incorporationNo"
              placeholder="Incorporation Number"
              onChange={formik.handleChange}
              value={formik.values.incorporationNo}
              {...formInputStyles}
            />
            <FormErrorMessage>{formik.errors.incorporationNo}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel {...formLabelStyles}>City</FormLabel>
            <Input
              type="text"
              name="city"
              placeholder="Enter your city"
              value={formik.values.city}
              onChange={formik.handleChange}
              {...formInputStyles}
            />
            <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.touched.state && formik.errors.state}
          >
            <FormLabel {...formLabelStyles}>State</FormLabel>
            <Input
              type="text"
              name="state"
              placeholder="Enter your state"
              value={formik.values.state}
              onChange={formik.handleChange}
              {...formInputStyles}
            />
            <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.touched.country && formik.errors.country}
          >
            <FormLabel {...formLabelStyles}>Country</FormLabel>
            <Select
              name="country"
              value={formik.values.country}
              placeholder="Select your country"
              onChange={formik.handleChange}
              {...formInputStyles}
            >
              {countriesList.map((option) => (
                <option value={option.iso2}>{option.name}</option>
              ))}
            </Select>
            <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.touched.zipCode && formik.errors.zipCode}
          >
            <FormLabel {...formLabelStyles}>Postal Code</FormLabel>
            <Input
              type="number"
              name="zipCode"
              placeholder="Enter your postal code"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              {...formInputStyles}
            />
            <FormErrorMessage>{formik.errors.zipCode}</FormErrorMessage>
          </FormControl>
          <Button
            variant="darkBrand"
            height={"50px"}
            onClick={formik.handleSubmit}
            isLoading={businessDetailState?.post?.loading}
          >
            Submit
          </Button>
        </Flex>
      </chakra.form>
    </Box>
  );
};

export default BusinessForm;
