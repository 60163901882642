import httpService from "../../interceptors/http";

export const postBankDetails = async ({
  accountId,
  currency,
  bankName,
  accountName,
  accountNumber,
  bankCodes,
  routingNumber,
  userId,
  country,
  type
}) => {
  return httpService().post(`/bank`, {
    accountId,
    currency,
    bankName,
    accountName,
    accountNumber,
    bankCodes,
    routingNumber,
    userId,
    country,
    type
  });
};

export const getBanks = async ({ userId }) => {
  return httpService().get(`/bank`, {
    params: {
      userId,
    },
  });
}

export const updateBankDetails = async ({ accountId,
  currency,
  bankName,
  accountName,
  accountNumber,
  bankCodes,
  routingNumber,
  userId,
  country, id }) => {
  return httpService().patch(`/bank/${id}`, {
    accountId,
    currency,
    bankName,
    accountName,
    accountNumber,
    bankCodes,
    routingNumber,
    userId,
    country,
  });
};


