//type here is the key for the main object in which all similar kind of data will be stored

export const saveDataToSession = (type = "userData", key = "", value = "") => {
  if (typeof window !== "undefined") {
    let storedData = window.sessionStorage.getItem(type);
    if (storedData) {
      storedData = JSON.parse(storedData);
      window.sessionStorage.setItem(
        type,
        JSON.stringify({ ...storedData, [key]: value })
      );
    } else {
      window.sessionStorage.setItem(type, JSON.stringify({ [key]: value }));
    }
  }
};

export const getDataFromSession = (type = "userData", key) => {
  if (typeof window !== "undefined") {
    let storedData = window.sessionStorage.getItem(type);
    if (storedData) {
      storedData = JSON.parse(storedData);
      return storedData[key] || false;
    }
  }
  return false;
};

export const clearDataFromSession = (type = "userData") => {
  window.sessionStorage.removeItem(type);
};
