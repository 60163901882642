import create from "zustand";
import produce from "immer";
import { getBanks, postBankDetails, updateBankDetails } from "service/bank-service";

const INITIAL_BANK_DETAIL_STATE = {
  post: {
    loading: false,
    email: "",
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
  get: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
  patch: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useBankDetailStore = create((set, get) => ({
  bankDetailState: INITIAL_BANK_DETAIL_STATE,
  postBankDetailAction: async ({
    accountId,
    currency,
    bankName,
    accountName,
    accountNumber,
    bankCodes,
    routingNumber,
    userId,
    country,
    type
  }) => {
    set(
      produce((state) => {
        state.bankDetailState.post.loading = true;
        state.bankDetailState.post.success.ok = false;
      })
    );
    try {
      const data = await postBankDetails({
        accountId,
        currency,
        bankName,
        accountName,
        accountNumber,
        bankCodes,
        routingNumber,
        userId,
        country,
        type
      });
      set(
        produce((state) => {
          state.bankDetailState.post.loading = false;
          state.bankDetailState.post.success.ok = true;
          state.bankDetailState.post.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.otpState.post.loading = false;
          state.orderState.post.failure.error = true;
          state.orderState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
  getBanksUsingId: async({ userId }) => {
    try {
      set(
        produce((state) => {
          state.bankDetailState.get.loading = true;
          state.bankDetailState.get.success.ok = false;
        })
      );
      const data = await getBanks({ userId });
      set(
        produce((state) => {
          state.bankDetailState.get.loading = false;
          state.bankDetailState.get.success.ok = true;
          state.bankDetailState.get.success.data = data;
        })
      );

      return data;
    } catch (e) {
      set(produce((state) => {
        state.storeState.get.loading = false;
        state.storeState.get.failure.error = true;
        state.storeState.get.failure.message = e.message;
      }));
    }
  },
  updateBankDetails: async({ accountId,
    currency,
    bankName,
    accountName,
    accountNumber,
    bankCodes,
    routingNumber,
    userId,
    country, id }) => {
    try {
      set(
        produce((state) => {
          state.bankDetailState.patch.loading = true;
          state.bankDetailState.patch.success.ok = false;
        })
      );
      const data = await updateBankDetails({
        accountId,
        currency,
        bankName,
        accountName,
        accountNumber,
        bankCodes,
        routingNumber,
        userId,
        country,
        id
      });

      set(
        produce((state) => {
          state.bankDetailState.patch.loading = false;
          state.bankDetailState.patch.success.ok = true;
          state.bankDetailState.patch.success.data = data;
        })
      );

      return data;
    } catch (e) {
      set(produce((state) => {
        state.storeState.get.loading = false;
        state.storeState.get.failure.error = true;
        state.storeState.get.failure.message = e.message;
      }));
    }
  },
}));

export default useBankDetailStore;
