import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import moment from "moment";
import { FiFilter } from "react-icons/fi";
import { BiRefresh, BiReset } from "react-icons/bi";

import TransfiGrid from "../../../utils/TransfiGrid";
import { startCase } from "lodash";
import PaymentsFilter from "./helper/paymentsFilter";

const Payments = () => {
    const gridRef = useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formValues, setFormValues] = useState({});
    const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [inputData, setInputData] = useState({});

    const columns = [
        {
            Header: "TRANSACTION NO",
            accessor: "paymentId",
            width: 220
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ value }) => {
                return startCase(value);
            },
        },
        {
            Header: "Buyer",
            accessor: 'buyer',
            width: 220
        },
        {
            Header: "AMOUNT",
            accessor: "invoiceAmount",
        },
        {
            Header: "CURRENCY",
            accessor: "invoiceCurrency",
        },
        {
            Header: "DATE",
            accessor: "createdAt",
            Cell: ({ value }) => {
                return moment(value).format("lll");
            },
            width: 220
        }
    ];

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({ dateRange: defaultDate });
        setInputData({});
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <div style={{ margin: "2%" }}>
                <div style={{ float: "right" }}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        onClick={() => handleOnReset()}
                        variant={"outline"}
                        colorScheme="#422AFB"
                        style={{ margin: "0 8px", color: "#422AFB" }}
                        size="sm"
                    >
                        Reset
                    </Button>
                    <Button
                        leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                        onClick={() => refreshGrid()}
                        variant={"outline"}
                        colorScheme="#422AFB"
                        style={{ margin: "0 0px", color: "#422AFB" }}
                        size="sm"
                    >
                        Refresh
                    </Button>
                    <Button
                        leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                        onClick={onOpen}
                        variant={"outline"}
                        colorScheme="#422AFB"
                        style={{ margin: "0 8px", color: "#422AFB" }}
                        size="sm"
                    >
                        Filter
                    </Button>
                </div>
                <br />
            </div>
            <PaymentsFilter
                onClose={onClose}
                isOpen={isOpen}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
            ></PaymentsFilter>
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/payments/list"}
                filters={{ filters }}
                selectRow={false}
            ></TransfiGrid>
        </Box>
    );
};

export default Payments; 