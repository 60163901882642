import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import AdminLayout from "./layouts/admin";
import { getJwtUser } from "./utils/jwt-utils";
import UserContext from "./contexts/UserContext";
import Profile from "./routes/auth/profile";
import Business from "./routes/auth/business";
import SignInCentered from "./routes/auth/signIn";
import GenerateBill from "routes/admin/bills/generatebill";

const PrivateRoute = ({ component: Component, auth, path, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

function App() {
  const [userId, setUserId] = useState(getJwtUser() || "");

  const isAuthenticated = userId ? true : false;
  const id = sessionStorage.getItem("userId");
  if (isEmpty(id)) localStorage.clear();

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ userId, setUserId }}>
        <Switch>
          <PrivateRoute
            path="/admin"
            component={AdminLayout}
            auth={isAuthenticated}
          />
          <PrivateRoute
            path="/auth/profile"
            component={Profile}
            auth={isAuthenticated}
          />
          <PrivateRoute
            path="/auth/business"
            component={Business}
            auth={isAuthenticated}
          />
          <PrivateRoute
            path="/admin/generatebill"
            component={GenerateBill}
            auth={isAuthenticated}
          />
          <Route path="/auth" component={SignInCentered} />
          <Redirect from="/" to="/auth" />
        </Switch>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
