import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, FormControl, chakra } from "@chakra-ui/react";
import { FormLabel, Heading, Input, Text, useToast } from "@chakra-ui/react";
import { useColorModeValue, FormErrorMessage } from "@chakra-ui/react";

// Custom components
import DefaultAuth from "../../../layouts/auth/Default";
import illustration from "../../../assets/img/auth/main-bg.png";
import { HSeparator } from "components/separator/Separator";

import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import VerifyOtp from "./verifyOtp";
import useSignInStore from "../../../store/sign-in";
import useOtpStore from "../../../store/verify-otp";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { getJwtUser } from "utils/jwt-utils";
import UserContext from "contexts/UserContext";

function SignIn() {
  const [loading, setLoading] = useState(false);
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  // const textColorBrand = useColorModeValue("brand.500", "white");
  // const brandStars = useColorModeValue("brand.500", "brand.400");
  // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  // const googleActive = useColorModeValue(
  //   { bg: "secondaryGray.300" },
  //   { bg: "whiteAlpha.200" }
  // );

  const history = useHistory();
  const [response, setResponse] = useState({});
  const { signInState, postSignInAction } = useSignInStore((state) => state);
  const { postOtpAction } = useOtpStore((state) => state);
  const { setUserId } = useContext(UserContext);
  const toast = useToast();

  const onSubmitHandler = async (email) => {
    try {
      const responseData = await postSignInAction({ email });
      setResponse(responseData);
      if (responseData?.message) {
        toast({
          title: "OTP sent Successfully",
          position: "top",
          status: "success",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        position: "top",
        duration: 3000,
      });
    }
  };

  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Please Enter your Email"),
  });

  const formik = useFormik({
    initialValues: { email: signInState.post.email || "" },
    validationSchema: signInSchema,
    onSubmit: ({ email }) => {
      if (email) onSubmitHandler(email);
    },
  });

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      const responseData = await postOtpAction({
        accessToken: response.access_token,
      });
      if (responseData.status === "verified") {
        sessionStorage.setItem("userId", getJwtUser());
        setUserId(getJwtUser());
        toast({
          title: "Signed In Successfully",
          position: "top",
          status: "success",
          duration: 2000,
        });
        history.push(
          responseData.isNewUser ? "/auth/profile" : "/admin/default"
        );
      } else {
        toast({
          title: "Email is not verified",
          position: "top",
          status: "error",
          duration: 2000,
        });
      }

      setLoading(false);
    },
    onError: (error) => {
      toast({
        title: error.message,
        position: "top",
        status: "error",
        duration: 2000,
      });
      console.log(error);
    },
  });

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="30px" mb="10px">
            Sign In to your account
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email to sign in !
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Button
            mb="26px"
            h="50px"
            borderRadius="12px"
            bg={"white"}
            color={googleText}
            fontWeight="700"
            _hover={googleHover}
            border={"1px"}
            borderColor={"#A0AEC0"}
            leftIcon={<FcGoogle fontSize="25px" />}
            onClick={login}
            isLoading={loading}
          >
            Sign in with Google
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <chakra.form>
              <Flex direction="column" gap={5}>
                <FormControl
                  isRequired
                  isInvalid={formik.touched.email && formik.errors.email}
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email
                  </FormLabel>
                  <Input
                    variant="auth"
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter Your email address"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                {isEmpty(response) || !response?.message ? (
                  <Button
                    fontWeight="700"
                    w="100%"
                    color={"white"}
                    bgColor={"#3183CE"}
                    _hover={"#3183CE"}
                    onClick={formik.handleSubmit}
                    isLoading={signInState.post.loading}
                    isDisabled={signInState.post.loading}
                  >
                    Get OTP
                  </Button>
                ) : (
                  <VerifyOtp />
                )}
              </Flex>
            </chakra.form>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
