import {
  Box,
  Text,
  Flex,
  Button,
  HStack,
  VStack,
  Image,
  Divider,
  Skeleton
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GrFormAdd } from "react-icons/gr";
import { CiViewList } from "react-icons/ci";
import { useHistory } from "react-router-dom";
import { isEmpty, toLower } from "lodash";

import inrFlag from "../../../assets/img/layout/inrFlag.svg"
import usdFlag from "../../../assets/img/layout/usdFlag.svg"
import { getJwtUser } from "utils/jwt-utils";
import Card from "components/card/Card";
import useBankDetailStore from "store/bank";
import ViewBankDetails from "./view-bank-details";

const BankDetail = () => {
  const history = useHistory();

  const { getBanksUsingId } = useBankDetailStore((state) => state);

  const [banks, setBanks] = useState([]);
  const [viewBankDetails, showViewBankDetails] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [primaryBank, setPrimaryBank] = useState({});
  const [secondaryBank, setSecondaryBank] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getBankData();
  }, [viewBankDetails]);

  const getBankData = async () => {
    const userId = getJwtUser();
    const data = await getBanksUsingId({ userId });
    data.map(bank => {
      if (bank.type === 'primary') {
        setPrimaryBank(bank);
      } else if (bank.type === 'secondary') {
        setSecondaryBank(bank);
      }
    });
    setBanks(data);
    setLoading(false);
  }

  const iconMapping = {
    inr: inrFlag,
    usd: usdFlag
  }

  return <Box width={"80%"} m="auto" pt={{ base: "130px", md: "80px", xl: "80px" }}>
    {viewBankDetails ? <ViewBankDetails showViewBankDetails={showViewBankDetails} selectedBank={selectedBank} /> :
      <Card p={5}>
        <Flex>
          <VStack spacing={"25px"} p={"2px"} leftIcon={""} >
            {isEmpty(primaryBank) ? <Skeleton isLoaded={!loading}><HStack boxShadow="2px 2px 5px rgba(0, 0, 0, 0.3)" borderRadius="md" >
              <Image m={5} src={inrFlag} w="60px" h="60px" />
              <VStack p={5} alignItems={"flex-start"} width="35vw">
                <Text flex={4} fontWeight="600" fontSize="md" color={"#1a4fd6"}>
                  INR Bank Account
                </Text>
                <Text fontWeight="400" fontSize="md">
                  Your foreign currency bank account which accepts payment in INR
                </Text>
                <Divider />
                <Text fontWeight="200" fontSize="xs">
                  A $15 fee may be applicable per payout in USD. Additional bank charges may also apply.
                </Text>
              </VStack>
              <VStack >
                <Button color={"#1a4fd6"} onClick={() => history.push("/admin/bank-details/add")} mr={"10px"} leftIcon={<GrFormAdd />} border="1px solid #422AFB" variant='solid'>
                  Add Bank Account
                </Button>
              </VStack>
            </HStack></Skeleton> : <Skeleton isLoaded={!loading}><HStack boxShadow="2px 2px 5px rgba(0, 0, 0, 0.3)" borderRadius="md" >
                <Image m={5} src={iconMapping[toLower(primaryBank.currency)]} w="60px" h="60px" />
              <VStack p={5} alignItems={"flex-start"} width="35vw">
                <Text flex={4} fontWeight="600" fontSize="md" color={"#1a4fd6"}>
                  {primaryBank.bankName}
                </Text>
                <Text fontWeight="400" fontSize="md">
                  {primaryBank.accountNumber}
                </Text>
                <Text fontWeight="200" fontSize="xs">
                  {primaryBank.currency} Bank Account
                </Text>
              </VStack>
              <VStack >
                <Button color={"#1a4fd6"} onClick={() => {
                  setSelectedBank(primaryBank);
                  showViewBankDetails(true)
                }
                } mr={"10px"} leftIcon={<CiViewList />} border="1px solid #422AFB" variant='solid'>
                  View Bank Details
                </Button>
              </VStack>
            </HStack></Skeleton>}

            {isEmpty(secondaryBank) ? <Skeleton isLoaded={!loading}><HStack boxShadow="2px 2px 5px rgba(0, 0, 0, 0.3)" borderRadius="md" >
              <Image m={5} src={usdFlag} w="60px" h="60px" />
              <VStack p={5} alignItems={"flex-start"}>
                <Text flex={4} fontWeight="600" fontSize="md" color={"#1a4fd6"}>
                  USD Bank Account
                </Text>
                <Text fontWeight="400" fontSize="md">
                  Your foreign currency bank account which accepts payment in USD
                </Text>
                <Divider />
                <Text fontWeight="200" fontSize="xs">
                  A $15 fee may be applicable per payout in USD. Additional bank charges may also apply.
                </Text>
              </VStack>
              <VStack >
                <Button color={"#1a4fd6"} onClick={() => history.push("/admin/bank-details/add")} mr={"10px"} leftIcon={<GrFormAdd />} border="1px solid #422AFB" variant='solid'>
                  Add Bank Account
                </Button>
              </VStack>
            </HStack></Skeleton> :
              <Skeleton isLoaded={!loading}>
                <HStack boxShadow="2px 2px 5px rgba(0, 0, 0, 0.3)" borderRadius="md" >
                  <Image m={5} src={iconMapping[toLower(secondaryBank.currency)]} w="60px" h="60px" />
                  <VStack p={5} alignItems={"flex-start"} width="35vw">
                    <Text flex={4} fontWeight="600" fontSize="md" color={"#1a4fd6"}>
                      {secondaryBank.bankName}
                    </Text>
                    <Text fontWeight="400" fontSize="md">
                      {secondaryBank.accountNumber}
                    </Text>
                    <Text fontWeight="200" fontSize="xs">
                      {secondaryBank.currency} Bank Account
                    </Text>
                  </VStack>
                  <VStack >
                    <Button color={"#1a4fd6"} onClick={() => {
                      setSelectedBank(secondaryBank);
                      showViewBankDetails(true)
                    }
                    } mr={"10px"} leftIcon={<CiViewList />} border="1px solid #422AFB" variant='solid'>
                      View Bank Details
                    </Button>
                  </VStack>
                </HStack>
              </Skeleton>
            }

          </VStack>
        </Flex>
      </Card>
        }
  </Box>
}

export default BankDetail;