import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import DefaultAuth from "../../../layouts/auth/Default";
import illustration from "../../../assets/img/auth/main-bg.png";
import BusinessForm from "./components/BusinessForm";

const Business = () => {
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "30px" }}
        mt={{ base: "25px", md: "7vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Text
            color="#1a4fd6"
            fontSize="26px"
            mb="10px"
            letterSpacing={0.1}
            fontWeight={600}
          >
            Business Details
          </Text>
          <Text
            mb="36px"
            ms="4px"
            fontWeight="500"
            fontSize="md"
            letterSpacing={0.1}
          >
            Enter Your Business Details
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "15px", md: "auto" }}
        >
          <BusinessForm />
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default Business;
