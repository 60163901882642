import React, { useState, useContext } from "react";
import { Button, useToast, FormControl, HStack } from "@chakra-ui/react";
import { PinInputField, FormLabel, PinInput } from "@chakra-ui/react";

import useSignInStore from "../../../store/sign-in";

import useOtpStore from "../../../store/verify-otp";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { getJwtUser } from "utils/jwt-utils";
import UserContext from "contexts/UserContext";

const VerifyOtp = () => {
  const [response, setResponse] = useState({});
  const { otpState, postOtpAction } = useOtpStore((state) => state);
  const { signInState } = useSignInStore((state) => state);
  const toast = useToast();
  const history = useHistory();
  const { setUserId } = useContext(UserContext);

  const onSubmitHandler = async (email, otp) => {
    try {
      const responseData = await postOtpAction({ email, otp });
      setResponse(responseData);
      const data = getJwtUser();
      sessionStorage.setItem("userId", data);
      setUserId(data);
      if (responseData && responseData?.status === "verified") {
        toast({
          title: "OTP verified Successfully",
          position: "top",
          status: "success",
          duration: 2000,
        });
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        duration: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: { email: signInState.post.email || "", otp: "" },
    onSubmit: ({ email, otp }) => {
      if (email && otp) onSubmitHandler(email, otp);
      else {
        toast({
          title: "Email is empty",
          status: "error",
          duration: 3000,
        });
      }
    },
  });

  const onOtpComplete = (otp) => {
    formik.setFieldValue("otp", otp);
  };

  const handleSignIn = () => {
    const data = getJwtUser();
    if (data) {
      toast({
        title: "Signed In Successfully",
        position: "top",
        status: "success",
        duration: 2000,
      });
      setTimeout(() => {
        history.push(
          otpState?.post?.success?.data?.isNewUser
            ? "/auth/profile"
            : "/admin/default"
        );
      }, 1000);
    }
  };

  return (
    <>
      <FormControl
        isRequired
        isInvalid={formik.touched.otp && formik.errors.otp}
      >
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          mb="8px"
        >
          Enter your otp
        </FormLabel>
        <HStack spacing="10">
          <HStack spacing="3">
            <PinInput onComplete={onOtpComplete} otp type="number">
              {[1, 2, 3, 4, 5, 6].map((e) => (
                <PinInputField key={e} />
              ))}
            </PinInput>
          </HStack>
          <Button
            colorScheme="teal"
            size="md"
            onClick={formik.handleSubmit}
            isLoading={otpState.post.loading}
            isDisabled={!formik.values.otp || response?.status === "verified"}
          >
            Verify
          </Button>
        </HStack>
      </FormControl>
      <Button
        fontSize="md"
        variant="solid"
        colorScheme="blue"
        fontWeight="500"
        w="100%"
        h="50"
        my="20px"
        isDisabled={isEmpty(response) ? true : false}
        onClick={() => handleSignIn()}
      >
        Sign In
      </Button>
    </>
  );
};

export default VerifyOtp;
