import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Flex,
  Heading,
  Input,
  VStack,
  Text,
  InputGroup,
  InputRightElement,
  Button,
  ModalFooter,
  useClipboard,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const ShowBillLink = (props) => {
  const { isBillOpen, onBillClose, linkContent } = props;
  const [value, setValue] = useState(linkContent);
  const { hasCopied, onCopy } = useClipboard(linkContent);
  const history = useHistory();

  const redirectToBills = () => {
    history.push("/admin/bills", { replace: true });
  };

  return (
    <Modal isOpen={isBillOpen} onClose={onBillClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={"column"} gap={5}>
            <Heading as="h3" size="md" textAlign={"center"} color="gray.700">
              Payment link has been created and sent to your Counterparty!
            </Heading>
            <VStack
              gap="3"
              p={3}
              bg={"gray.100"}
              w="90%"
              m="0 auto"
              textAlign={"center"}
              borderRadius="12px"
              boxShadow="sm"
            >
              <Heading as="h6" size="md" color={"gray.700"}>
                Share the agreeent link
              </Heading>
              <InputGroup size="md">
                <Input pr="4.5rem" type={"text"} value={linkContent} />
                <InputRightElement width="4.5rem">
                  <Button
                    onClick={onCopy}
                    colorScheme={"green"}
                    h="1.75rem"
                    size="sm"
                  >
                    {hasCopied ? "Copied!" : "Copy"}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Text fontSize={"14px"} color="gray.500">
                Copy and paste the link above into email, chats or apps
              </Text>
            </VStack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant={"brand"} mr={3} onClick={onBillClose}>
            Create new bill
          </Button>
          <Button variant={"darkBrand"} onClick={redirectToBills}>
            Back To bills
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShowBillLink;
