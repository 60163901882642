import React, { useState, useRef } from "react";
import { Box } from "@chakra-ui/react";
import moment from "moment";
import { Button, Link, HStack, useDisclosure } from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import { BiRefresh, BiReset } from "react-icons/bi";

import TransfiGrid from "../../../utils/TransfiGrid";
import BillsFilter from "./components/BillsFilter";

const Bills = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = useState({});
  const defaultDate = {
    startDate: moment(new Date()).subtract(2, "months"),
    endDate: moment(new Date()),
  };
  const [filters, setFilters] = useState({ dateRange: defaultDate });
  const [dateRange, setDateRange] = useState(defaultDate);
  const [inputData, setInputData] = useState({});
  const gridRef = useRef();

  const refreshGrid = () => {
    gridRef.current.refreshGrid();
  };

  const handleOnReset = () => {
    setDateRange(defaultDate);
    setFilters({ dateRange: defaultDate });
    setFormValues({ dateRange: defaultDate });
    setInputData({});
  };

  const columns = [
    {
      Header: "Transaction NO.",
      accessor: "billId",
      width: 200,
    },
    {
      Header: "Seller Email",
      accessor: "sellerDetails.email",
      width: 220,
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Buyer Email",
      accessor: "buyerDetails.email",
      width: 220,
    },
    {
      Header: "Buyer Id",
      accessor: "buyerId",
      width: 220,
    },
    {
      Header: "Amount",
      accessor: "invoiceAmount",
      width: 100,
    },

    {
      Header: "Currency",
      accessor: "invoiceCurrency",
      width: 100,
    },
    {
      Header: "DATE",
      accessor: "createdAt",
      Cell: ({ value }) => moment(value).format("lll"),
      width: 180,
    },
  ];

  const buttonStyles = {
    variant: "outline",
    colorScheme: "#422AFB",
    size: "sm",
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <HStack my={3} style={{ float: "right" }}>
        <Button {...buttonStyles} style={{ color: "#422AFB" }}>
          Export
        </Button>
        <Link href="/admin/bills/generate">
          <Button {...buttonStyles} style={{ color: "#422AFB" }}>
            Create Bill
          </Button>
        </Link>

        <Button
          leftIcon={<BiReset style={{ fontSize: "20px" }} />}
          onClick={() => handleOnReset()}
          {...buttonStyles}
          style={{ margin: "0 8px", color: "#422AFB" }}
        >
          Reset
        </Button>
        <Button
          leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
          onClick={() => refreshGrid()}
          {...buttonStyles}
          style={{ margin: "0 0px", color: "#422AFB" }}
        >
          Refresh
        </Button>
        <Button
          leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
          onClick={onOpen}
          {...buttonStyles}
          style={{ margin: "0 8px", color: "#422AFB" }}
        >
          Filter
        </Button>
        <br />
      </HStack>

      <BillsFilter
        onClose={onClose}
        isOpen={isOpen}
        formValues={formValues}
        setFormValues={setFormValues}
        filters={filters}
        setFilters={setFilters}
        dateRange={dateRange}
        setDateRange={setDateRange}
        inputData={inputData}
        setInputData={setInputData}
        handleOnReset={handleOnReset}
      />
      <TransfiGrid
        ref={gridRef}
        columns={columns}
        dataUrl={"/api/bill/list"}
        filters={{ filters }}
        selectRow={false}
      ></TransfiGrid>
    </Box>
  );
};

export default Bills;
