import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdHome, MdPerson, MdInsertChartOutlined } from "react-icons/md";
import { CiMoneyBill } from "react-icons/ci";
import { RiAccountCircleFill } from "react-icons/ri";
import { MdPayment } from "react-icons/md"

import MainDashboard from "./admin/default";
import Profile from "./auth/profile";
import Business from "./auth/business";
import BankDetails from "./admin/bank";
import BankDetailsV2 from "./admin/bank/bank-details";
import ViewBankDetails from "./admin/bank/view-bank-details";
import AccountDetails from "./admin/account";

import SignInCentered from "./auth/signIn";
import Bills from "./admin/bills";
import GenerateBill from "./admin/bills/generatebill";
import ViewBill from "./admin/bills/viewbill";
import Reports from "./admin/reports";
import Payments from "./admin/payments";

let routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Bills",
    layout: "/admin",
    path: "/bills",
    icon: <Icon as={CiMoneyBill} width="20px" height="20px" color="inherit" />,
    component: Bills,
  },
  {
    name: "Bank Details",
    layout: "/admin",
    path: "/bank-details/add",
    hidden: true,
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: BankDetails,
  },
  {
    name: "Bank Details",
    layout: "/admin",
    path: "/bank-details",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: BankDetailsV2,
  },
  {
    name: "Bank Details",
    layout: "/admin",
    path: "/bank-details/view",
    hidden: true,
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: ViewBankDetails,
  },
  {
    name: "Payments",
    layout: "/admin",
    path: "/payments",
    icon: <Icon as={MdPayment} width="20px" height="20px" />,
    component: Payments,
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "/reports",
    icon: <Icon as={MdInsertChartOutlined} width="20px" height="20px" />,
    component: Reports,
  },
  {
    name: "Account",
    layout: "/admin",
    path: "/account-details",
    icon: <Icon as={RiAccountCircleFill} width="20px" height="20px" />,
    component: AccountDetails,
  },
  {
    name: "Create Payment Link",
    layout: "/admin",
    path: "/bills/generate",
    component: GenerateBill,
    display: "none",
  },
  {
    name: "Bill Detail",
    layout: "/admin",
    path: `/bills/:id`,
    component: ViewBill,
    display: "none",
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    component: SignInCentered,
    display: "none",
  },
  {
    name: "Profile",
    layout: "/auth",
    path: "/profile",
    component: Profile,
    display: "none",
  },
  {
    name: "Business Details",
    layout: "/auth",
    path: "/business",
    component: Business,
    display: "none",
  },
];

export default routes;
