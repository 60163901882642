import React from "react";
import { startCase } from "lodash";
import { useFormik } from "formik";
import sc from "states-cities-db";
import { Modal, ModalOverlay, ModalContent, Center } from "@chakra-ui/react";
import { ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { FormControl, FormLabel, Grid, Button, chakra } from "@chakra-ui/react";
import { Input, FormErrorMessage, GridItem, useToast } from "@chakra-ui/react";
import { InputGroup, InputLeftAddon, Select } from "@chakra-ui/react";

import useProfileStore from "store/profile";
import { profileValidationSchema } from "../utils";

const ProfileModal = (props) => {
  const { isOpen, onClose, profileData, userId, getProfile } = props;

  const { postProfieAction } = useProfileStore((state) => state);
  const countriesList = sc.getCountries();
  const toast = useToast();

  const onCountrySelect = (value) => {
    const code = countriesList.filter((option) => option.iso2 === value);
    formik.setFieldValue("country", value);
    formik.setFieldValue("contactCode", code[0].prefix);
  };

  const onSubmitHandler = async (data) => {
    try {
      const responseData = await postProfieAction({ userId, data });
      if (responseData) {
        toast({
          title: "Profile updated Successfully",
          position: "top",
          status: "success",
          duration: 3000,
        });
        getProfile();
        onClose();
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        position: "top",
        duration: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: profileData || {
      firstName: "",
      lastName: "",
      country: "",
      contact: "",
      contactCode: "",
      accountType: "",
      line1: "",
      city: "",
      district: "",
      postalCode: "",
    },
    enableReinitialize: true,
    validationSchema: profileValidationSchema,
    onSubmit: (data) => {
      if (data) onSubmitHandler(data);
    },
  });

  const formLabelStyles = {
    display: "flex",
    ms: "4px",
    fontSize: "sm",
    fontWeight: "500",
    mb: "7px",
  };

  const formInputStyles = {
    fontSize: "md",
    fontWeight: "500",
    size: "lg",
  };

  const onChangeType = (accountType) => {
    formik.setFieldValue("accountType", accountType);
  };

  return (
    <>
      <Modal onClose={onClose} size={"4xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg={"#F7FAFC"}>
          <ModalHeader
            bgColor={"#B794F4"}
            letterSpacing={0.1}
            color={"#2D3748"}
            textAlign={"center"}
            fontWeight={600}
          >
            Edit Personal Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mt={4}>
            <chakra.form>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} fontWeight={600}>
                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  >
                    <FormLabel {...formLabelStyles}>First Name</FormLabel>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="Enter your first name"
                      {...formInputStyles}
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                    />
                    <FormErrorMessage>
                      {formik.errors.firstName}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  >
                    <FormLabel {...formLabelStyles}>Last Name</FormLabel>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Enter your last name"
                      {...formInputStyles}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                      {formik.errors.lastName}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={formik.touched.country && formik.errors.country}
                  >
                    <FormLabel {...formLabelStyles}>Country</FormLabel>
                    <Select
                      name="country"
                      value={formik.values.country}
                      defaultValue={profileData.country}
                      placeholder="Select your country"
                      onChange={(e) => onCountrySelect(e.target.value)}
                      {...formInputStyles}
                    >
                      {countriesList.map((option) => (
                        <option value={option.iso2}>{option.name}</option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={formik.touched.contact && formik.errors.contact}
                  >
                    <FormLabel {...formLabelStyles}>Contact</FormLabel>
                    <InputGroup {...formInputStyles}>
                      <InputLeftAddon
                        children={
                          formik.values.contactCode || profileData?.contactCode
                        }
                        width="auto"
                        minW={"90px"}
                      />
                      <Input
                        type="tel"
                        placeholder="Phone number"
                        name="contact"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                        {...formInputStyles}
                      />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.contact}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={
                      formik.touched.accountType && formik.errors.accountType
                    }
                  >
                    <FormLabel {...formLabelStyles}>Type</FormLabel>
                    <Select
                      name="accountType"
                      value={formik.values.accountType}
                      defaultValue={profileData.accountType}
                      placeholder="Select your type"
                      onChange={(e) => onChangeType(e.target.value)}
                      {...formInputStyles}
                    >
                      {["business", "individual"].map((option) => (
                        <option value={option}>{startCase(option)}</option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={formik.touched.city && formik.errors.city}
                  >
                    <FormLabel {...formLabelStyles}>City</FormLabel>
                    <Input
                      type="text"
                      name="city"
                      placeholder="Enter your city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      {...formInputStyles}
                    />
                    <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={
                      formik.touched.district && formik.errors.district
                    }
                  >
                    <FormLabel {...formLabelStyles}>District</FormLabel>
                    <Input
                      type="text"
                      name="district"
                      placeholder="Enter your district"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      {...formInputStyles}
                    />
                    <FormErrorMessage>
                      {formik.errors.district}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={formik.touched.line1 && formik.errors.line1}
                  >
                    <FormLabel {...formLabelStyles}>Address</FormLabel>
                    <Input
                      type="address"
                      name="line1"
                      placeholder="Enter your address"
                      value={formik.values.line1}
                      onChange={formik.handleChange}
                      {...formInputStyles}
                    />
                    <FormErrorMessage>{formik.errors.line1}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem w="90%">
                  <FormControl
                    isRequired
                    isInvalid={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  >
                    <FormLabel {...formLabelStyles}>Postal Code</FormLabel>
                    <Input
                      type="number"
                      name="postalCode"
                      placeholder="Enter your postal code"
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                      {...formInputStyles}
                    />
                    <FormErrorMessage>
                      {formik.errors.postalCode}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </chakra.form>
          </ModalBody>
          <Center my={6}>
            <Button onClick={formik.handleSubmit} colorScheme="teal" me={3}>
              Save
            </Button>
            <Button colorScheme="purple" onClick={onClose}>
              Close
            </Button>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ProfileModal;
