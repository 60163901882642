import create from "zustand";
import produce from "immer";
import {
  postBusinessDetails,
  getBusinessDetails,
} from "service/business-service";

const INITIAL_BUSINESS_DETAIL_STATE = {
  post: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
  get: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useBusinessDetailStore = create((set, get) => ({
  businessDetailState: INITIAL_BUSINESS_DETAIL_STATE,
  getBusinessDetailAction: async (userId) => {
    set(
      produce((state) => {
        state.businessDetailState.get.loading = true;
      })
    );

    try {
      const data = await getBusinessDetails(userId);
      set(
        produce((state) => {
          state.businessDetailState.get.loading = false;
          state.businessDetailState.get.success.ok = true;
          state.businessDetailState.get.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.businessDetailState.post.loading = false;
          state.businessDetailState.post.failure.error = true;
          state.businessDetailState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
  postBusinessDetailAction: async (data) => {
    set(
      produce((state) => {
        state.businessDetailState.post.loading = true;
        state.businessDetailState.post.success.ok = false;
      })
    );
    try {
      const responseData = await postBusinessDetails(data);
      set(
        produce((state) => {
          state.businessDetailState.post.loading = false;
          state.businessDetailState.post.success.ok = true;
          state.businessDetailState.post.success.data = responseData;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.otpState.post.loading = false;
          state.orderState.post.failure.error = true;
          state.orderState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
}));

export default useBusinessDetailStore;
