import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef, useContext } from "react";
import { useRowSelect, useTable, useMountedLayoutEffect } from "react-table";
import { useHistory, Link } from "react-router-dom";
import queryString from 'query-string';
import { isEqual, remove } from "lodash";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    IconButton,
    Text,
    Tooltip,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Box,
    Stack,
    Card,
    CardBody,
    Skeleton
} from "@chakra-ui/react";

import {
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ArrowBackIcon
} from "@chakra-ui/icons";

import { callApiWithToken } from "./utils";

const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const headerColors = {
    info: "#b3e5fc",
    warning: "#ffcc80"
}

const existingQueryParams = () => {
    const qParams = queryString.parse(window.location.search);

    return {
        ...qParams,
        pageIndex: qParams.pageIndex ? Number(qParams.pageIndex) : 0,
        pageSize: qParams.pageSize ? Number(qParams.pageSize) : 10
    };
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const IndeterminateCheckbox1 = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const TransfiGrid = (props, ref) => {
    const { columns, dataUrl, filters, compactView, condensedColumns, condensedUrl,
        onChangeSelectedRow, selectRow = false, initialState, method = "POST" } = props;
        const env = process.env.NODE_ENV;
    
    remove(columns, (column) => column.show === false);
    let eQueryParams = existingQueryParams();
    const [pageIndex, setPageIndex] = useState(eQueryParams?.pageIndex || 0);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(eQueryParams?.pageSize || 10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [criterias, setCriterias] = useState(filters);
    const [environment, setEnvironment] = useState(env);

    const prevState = usePrevious({ pageIndex, pageCount, pageSize, data, criterias, environment }) || {};

    // const navigate = useNavigate();
    let history = useHistory()

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        selectedFlatRows,
        state: { selectedRowIds }
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            initialState
        },
        useRowSelect,
        (hooks) => {
            selectRow && hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    width: '10',
                    Cell: ({ row }) => {
                        return (
                            <div>
                                <IndeterminateCheckbox1
                                    disabled={row.original.visits > 65}
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        );
                    }
                },
                ...columns
            ]);
        }
    );

    useMountedLayoutEffect(() => {
        onChangeSelectedRow && onChangeSelectedRow(selectedFlatRows.map(val => val.original));
    }, [selectedRowIds]);

    useEffect(() => {
        const qParams = "?" + queryString.stringify({
            ...eQueryParams,
            pageIndex: pageIndex || 0,
            pageSize: pageSize || 10
        }).toString();

        let areSameCriterias = isEqual(filters, prevState.criterias);
        if (!isEqual(filters, prevState.criterias)) {
            setCriterias(filters)
        }

        if (!isEqual(env, prevState.environment)) {
            setEnvironment(env)
        }

        if (prevState && (pageIndex != prevState.pageIndex || pageSize != prevState.pageSize || !areSameCriterias || env != prevState.environment)) {
            window.history.replaceState(null, null, qParams);
            fetchData();
        }
    }, [pageIndex, pageSize, filters, env]);

    const fetchData = async () => {
        const { onLoadDataCompletion } = props || {};
        setLoading(true);
        let body = {
            ...filters,
            page: pageIndex,
            limit: pageSize
        }
        const response = await callApiWithToken(method, dataUrl, body);
        const { data = [], pages } = response;
        
        setData(data);
        setPageCount(pages);
        setLoading(false);
        onLoadDataCompletion && onLoadDataCompletion(data);
    }

    useImperativeHandle(ref, () => ({
        refreshGrid() {
            fetchData();
        }
    }))

    const onPageChange = (pageValue) => {
        const qParams = "?" + queryString.stringify({
            ...eQueryParams,
            pageIndex: pageIndex || 0,
            pageSize: pageSize || 10
        }).toString();

        window.history.replaceState(null, null, qParams);
        setPageIndex(pageValue);
    }

    const pageSizeChange = (sizeValue) => {
        const qParams = "?" + queryString.stringify({
            ...eQueryParams,
            pageIndex: pageIndex || 0,
            pageSize: pageSize || 10
        }).toString();

        window.history.replaceState(null, null, qParams);
        setPageSize(sizeValue);
    }

    return (
        // !loading ?
        <Skeleton isLoaded={!loading}>
                {
                    compactView ? <div style={{ backgroundColor: 'white', paddingTop: '.5rem', paddingBottom: '.5rem' }}>
                        <Tooltip label="Go Back">
                            <IconButton
                                bg={'#EDF2F6'}
                                onClick={() => history.push(condensedUrl)}
                                icon={<ArrowBackIcon h={6} w={6} />}
                            />
                        </Tooltip>
                        <span style={{ color: '#4ACDED', fontSize: '1.25rem', marginLeft: '2rem', marginTop: '0.125rem' }}>{compactView}</span>
                    </div> : <></>
                }
                <Box
                    bg={compactView ? '#e3e4e6' : 'white'}
                    w='100%'
                    pr={compactView ? 0 : 4}
                    pt={4} pl={4}
                    pb={compactView ? 4 : 0}
                    maxWidth='80vw' borderRadius={compactView ? 4 : 0}
                    height={compactView ? '64vh' : 'auto'} overflow={compactView ? 'scroll' : 'auto'}>
                    {
                        !compactView ?
                            <Box bg='white' w='100%' p={4} color='black' overflowX="auto" maxWidth='80vw' >
                                <Table {...getTableProps()} size="sm" >
                                    <Thead>
                                        {headerGroups.map((headerGroup) => (
                                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <Th
                                                        // bgColor={column.color}
                                                        bgColor={headerColors[column.color]}
                                                        minWidth={column.width}
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render("Header")}
                                                    </Th>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <Tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                                        );
                                                    })}
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </Box>
                            : <Stack spacing='6'>
                                {data.map((row, i) => {
                                    let value = row[condensedColumns?.accessor];
                                    return <Link to={`${condensedUrl}/${value}`}>
                                        <Card
                                            id={`condensed-${value}`}
                                            key={value}
                                            style={{
                                                backgroundColor: `${compactView === value ? 'white' : '#F8F9FB'}`,
                                                borderLeft: `${compactView === value ? '3px solid #4ACDED' : 'none'}`,
                                                borderRight: `${compactView === value ? '3px solid #4ACDED' : 'none'}`,
                                                borderRadius: '4px',
                                                boxShadow: `${compactView === value ? '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)' : 'none'}`,
                                                marginRight: '1rem',
                                                scale: `${compactView === value ? '1.05' : '1'}`
                                            }}>
                                            <CardBody style={{ display: 'inline-block' }}>
                                                {(condensedColumns?.Cell({ row, value }))}
                                            </CardBody>
                                        </Card>
                                    </Link>
                                })}
                            </Stack>
                    }
                </Box>
                <Flex justifyContent="space-between" m={4} alignItems="center" bg={'white'}>
                    <Flex>
                        <Tooltip label="First Page">
                            <IconButton
                                bg={compactView ? '#d2d2d4' : '#EDF2F6'}
                                onClick={() => onPageChange(0)}
                                isDisabled={!pageIndex}
                                icon={<ArrowLeftIcon h={3} w={3} />}
                                mr={4}
                            />
                        </Tooltip>
                        <Tooltip label="Previous Page">
                            <IconButton
                                bg={compactView ? '#d2d2d4' : '#EDF2F6'}
                                onClick={() => onPageChange(pageIndex - 1)}
                                isDisabled={!pageIndex}
                                icon={<ChevronLeftIcon h={6} w={6} />}
                            />
                        </Tooltip>
                    </Flex>

                    {!compactView ?
                        <Flex alignItems="center">
                            <Text flexShrink="0" mr={8}>
                                Page{" "}
                                <Text fontWeight="bold" as="span">
                                    {pageCount ? pageIndex + 1 : 0}
                                </Text>{" "}
                                of{" "}
                                <Text fontWeight="bold" as="span">
                                    {pageCount}
                                </Text>
                            </Text>
                            <Text flexShrink="0">Go to page:</Text>{" "}
                            <NumberInput
                                ml={2}
                                mr={8}
                                w={28}
                                min={1}
                                max={pageCount}
                                onChange={(value) => {
                                    const page = value ? value - 1 : 0;
                                    onPageChange(page);
                                }}
                                defaultValue={pageIndex + 1}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            <Select
                                w={32}
                                value={pageSize}
                                onChange={(e) => {
                                    pageSizeChange(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </Select>
                        </Flex> : <></>
                    }
                    <Flex>
                        <Tooltip label="Next Page">
                            <IconButton
                                bg={compactView ? '#d2d2d4' : '#EDF2F6'}
                                onClick={() => onPageChange(pageIndex + 1)}
                                isDisabled={(pageIndex === (pageCount - 1))}
                                icon={<ChevronRightIcon h={6} w={6} />}
                            />
                        </Tooltip>
                        <Tooltip label="Last Page">
                            <IconButton
                                bg={compactView ? '#d2d2d4' : '#EDF2F6'}
                                onClick={() => onPageChange(pageCount - 1)}
                                isDisabled={(pageIndex === (pageCount - 1))}
                                icon={<ArrowRightIcon h={3} w={3} />}
                                ml={4}
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Skeleton>
    );
}

export default forwardRef(TransfiGrid);
