import { Box, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import ReportsTable from "./components/ReportsTable";

const Reports = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ReportsTable />
    </Box>
  );
};

export default Reports;
