import React, { useEffect, useState } from "react";
import { Box, Heading, Text, chakra, Flex, Select } from "@chakra-ui/react";
import { FormControl, FormLabel, useToast, Divider } from "@chakra-ui/react";
import { Stack, HStack, useDisclosure, Input, Button } from "@chakra-ui/react";
import { FormErrorMessage, Checkbox, Progress } from "@chakra-ui/react";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { MdDeleteForever } from "react-icons/md";
import * as Yup from "yup";

import { getJwtUser } from "utils/jwt-utils";
import Card from "components/card/Card";
import useBillDetailStore from "store/bill";
import useProfileStore from "store/profile";
import AddUserDetail from "./addUserModal";
import ShowBillLink from "./showBillUrlModal";

const GenerateBill = () => {
  const userId = getJwtUser();
  const [sellerDetails, setSellerDetails] = useState();
  const [isChecked, setIsChecked] = useState(false);
  let randomString = Math.random().toString(36);
  const [inputKey, setInputKey] = useState(randomString);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLinkModalOpen,
    onOpen: onLinkModalOpen,
    onClose: onLinkModalClose,
  } = useDisclosure();
  const toast = useToast();

  const { postBillDetailAction } = useBillDetailStore((state) => state);
  const { profileState, getProfileAction } = useProfileStore((state) => state);
  const [paymentLink, setPaymentLink] = useState();

  const loadData = async () => {
    const responseData = await getProfileAction({ userId });
    if (responseData) {
      const { buyersData = [] } = responseData || {};
      setSellerDetails(responseData);
      buyersData && setSelectedBuyer(buyersData[buyersData.length - 1]);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const [selectedBuyer, setSelectedBuyer] = useState(
    sellerDetails?.buyersData?.[0] || {}
  );
  const importersArray = sellerDetails?.buyersData || [];

  const billSubmitHandler = async (data) => {
    data.buyerId = selectedBuyer?.userId;
    data.sellerId = sellerDetails?.userId;
    try {
      const userId = getJwtUser();
      const responseData = await postBillDetailAction({ data, userId });
      if (responseData) {
        toast({
          title: "Bill details added successfully",
          position: "top",
          status: "success",
          duration: 3000,
        });
        setPaymentLink(responseData.link);
        onLinkModalOpen();
      } else if (responseData && responseData?.message) {
        toast({
          title: responseData.message,
          position: "top",
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        position: "top",
        duration: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      txnDescription: "",
      invoiceCurrency: "",
      invoiceAmount: "",
      file: {},
    },
    validationSchema: Yup.object({
      invoiceAmount: Yup.string().required("Please add Invoice Amount"),
      invoiceCurrency: Yup.string().required(
        "Please add invoice currency type"
      ),
      txnDescription: Yup.string().required(
        "Please add Transaction desccription"
      ),
    }),
    onSubmit: (data) => {
      if (data) billSubmitHandler(data);
    },
  });

  const onChangeBuyer = (value) => {
    if (value === "add") onOpen();
    else {
      const selectedBuyer = sellerDetails.buyersData.filter(
        (data) => data.userId === value
      );
      setSelectedBuyer(...selectedBuyer);
    }
  };

  const formLabelStyles = {
    display: "flex",
    ms: "4px",
    fontSize: "md",
    fontWeight: "600",
    mb: "8px",
  };

  const onChangeFileUpload = (file) => {
    try {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64String = reader.result.split(",")[1];
          const data = {
            base64: base64String,
            userId,
            fileName: file.name,
            mimetype: file.type,
          };
          formik.setFieldValue("file", data);
        };
      }
    } catch (error) {
      setInputKey(randomString);
      toast({
        title: error.message,
        position: "top",
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <Box width={"80%"} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {sellerDetails && (
        <>
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            <Heading as="h4" size="lg">
              Create Payment Link
            </Heading>
          </Flex>

          <chakra.form>
            <Flex direction={"column"} gap={5}>
              {/* Seller details  */}
              <Card
                direction="column"
                w="100%"
                px={5}
                overflowX={{ sm: "scroll", lg: "hidden" }}
                borderRadius="5px"
              >
                <Stack mb="5">
                  <Heading as="h4" size="md">
                    1. Seller Details
                  </Heading>
                  <Divider border={"1px"} borderColor={"brand.500"} />
                </Stack>

                <Flex direction={"column"} gap={5}>
                  <Box>
                    <HStack>
                      <Text fontSize="md" fontWeight="700">
                        Company Name:{" "}
                      </Text>
                      <Text fontWeight={"300"} fontSize="md">
                        Transfi
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack>
                      <Text fontSize="md" fontWeight="700">
                        Contact Person:{" "}
                      </Text>
                      <Text fontWeight={"300"} fontSize="md">
                        {profileState?.get.success.data?.firstName || "NA"}{" "}
                        {profileState?.get.success.data?.lastName}
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack>
                      <Text fontSize="md" fontWeight="700">
                        Contact Email:{" "}
                      </Text>
                      <Text fontWeight={"300"} fontSize="md">
                        {profileState?.get.success.data?.email}
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack>
                      <Text fontSize="md" fontWeight="700">
                        Country:
                      </Text>
                      <Text fontWeight={"300"} fontSize="md">
                        {profileState.get.success.data?.country}
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack>
                      <Text fontSize="md" fontWeight="700">
                        Contact Number:{" "}
                      </Text>
                      <Text fontWeight={"300"} fontSize="md">
                        {profileState.get.success.data?.contact}
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack>
                      <Text fontSize="md" fontWeight="700">
                        Customer ID:
                      </Text>
                      <Text fontWeight={"300"} fontSize="md">
                        {profileState.get.success.data?.userId}
                      </Text>
                    </HStack>
                  </Box>
                </Flex>
              </Card>

              {/* Buyer details */}
              <Card
                direction="column"
                w="100%"
                px={5}
                overflowX={{ sm: "scroll", lg: "hidden" }}
                borderRadius="5px"
              >
                <Stack mb="5">
                  <Heading as="h4" size="md">
                    2. Buyer Details
                  </Heading>
                  <Divider border={"1px"} borderColor={"brand.500"} />
                </Stack>

                {!isEmpty(importersArray) ? (
                  <Flex direction={"column"} gap={5}>
                    <Box>
                      <FormControl>
                        <FormLabel fontSize="md" fontWeight="700">
                          Select Buyer
                        </FormLabel>
                        <Select
                          placeholder="Select your buyer"
                          size="md"
                          name="buyer"
                          value={selectedBuyer?.userId}
                          defaultValue={selectedBuyer?.userId}
                          onChange={(e) => onChangeBuyer(e.target.value)}
                        >
                          <option value="add">Add new buyer</option>
                          {importersArray &&
                            importersArray.map((buyer, idx) => (
                              <option key={idx} value={buyer.userId}>
                                {buyer.email}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <HStack>
                        <Text fontSize="md" fontWeight="700">
                          Company Name:{" "}
                        </Text>
                        <Text fontSize="md" fontWeight="300">
                          {selectedBuyer?.businessName}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack>
                        <Text fontSize="md" fontWeight="700">
                          Contact Person:{" "}
                        </Text>
                        <Text fontSize="md" fontWeight="300">
                          {selectedBuyer?.firstName}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack>
                        <Text fontSize="md" fontWeight="700">
                          Contact Email:{" "}
                        </Text>
                        <Text fontSize="md" fontWeight="300">
                          {selectedBuyer?.email}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack>
                        <Text fontSize="md" fontWeight="700">
                          Country:{" "}
                        </Text>
                        <Text fontSize="md" fontWeight="300">
                          {selectedBuyer?.country}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack>
                        <Text fontSize="md" fontWeight="700">
                          Contact Number:{" "}
                        </Text>
                        <Text fontSize="md" fontWeight="300">
                          {selectedBuyer?.contact}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack>
                        <Text fontSize="md" fontWeight="700">
                          Customer ID:{" "}
                        </Text>
                        <Text fontSize="md" fontWeight="300">
                          {selectedBuyer?.userId}
                        </Text>
                      </HStack>
                    </Box>
                  </Flex>
                ) : (
                  <Button
                    variant="brand"
                    onClick={() => onOpen()}
                    width={"20%"}
                  >
                    Add New Buyer
                  </Button>
                )}
                <AddUserDetail
                  isOpen={isOpen}
                  onClose={onClose}
                  onComplete={onChangeBuyer}
                  loadData={loadData}
                  sellerDetails={sellerDetails}
                  setSellerDetails={setSellerDetails}
                />
              </Card>

              {/* Transaction details */}
              <Card
                direction="column"
                w="100%"
                px={5}
                overflowX={{ sm: "scroll", lg: "hidden" }}
                borderRadius="5px"
              >
                <Stack mb="5">
                  <Heading as="h4" size="md">
                    3. Transaction Details
                  </Heading>
                  <Divider border={"1px"} borderColor={"brand.500"} />
                </Stack>
                <Flex direction={"column"} gap={5}>
                  <HStack gap={5}>
                    <FormControl
                      isRequired
                      isInvalid={
                        formik.errors.invoiceCurrency &&
                        formik.touched.invoiceCurrency
                      }
                    >
                      <FormLabel {...formLabelStyles}>
                        Invoice Currency
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        fontWeight="500"
                        size="lg"
                        type="text"
                        name="invoiceCurrency"
                        placeholder="Invoice currency type ex: USD"
                        onChange={formik.handleChange}
                        value={formik.values.invoiceCurrency}
                      />
                      <FormErrorMessage>
                        {formik.errors.invoiceCurrency}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isRequired
                      isInvalid={
                        formik.errors.invoiceAmount &&
                        formik.touched.invoiceAmount
                      }
                    >
                      <FormLabel {...formLabelStyles}>Invoice Amount</FormLabel>
                      <Input
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        fontWeight="500"
                        size="lg"
                        type="text"
                        name="invoiceAmount"
                        placeholder="Invoice Amount"
                        onChange={formik.handleChange}
                        value={formik.values.invoiceAmount}
                      />
                      <FormErrorMessage>
                        {formik.values.invoiceAmount}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>

                  <FormControl
                    isRequired
                    isInvalid={
                      formik.errors.txnDescription &&
                      formik.touched.txnDescription
                    }
                  >
                    <FormLabel {...formLabelStyles}>
                      Transaction Description
                    </FormLabel>
                    <Input
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      fontWeight="500"
                      size="lg"
                      type="text"
                      name="txnDescription"
                      placeholder="Transacction Description"
                      onChange={formik.handleChange}
                      value={formik.values.txnDescription}
                    />
                    <FormErrorMessage>
                      {formik.errors.txnDescription}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <Flex direction={{ base: "column", md: "row" }}>
                      <FormLabel
                        {...formLabelStyles}
                        w={{ md: "300px" }}
                        mt={2}
                      >
                        Upload Invoice
                      </FormLabel>
                      <Input
                        type="file"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        size={"md"}
                        fontWeight={"500"}
                        name="invoiceFile"
                        key={inputKey || ""}
                        py={2}
                        variant="unstyled"
                        onChange={(e) => onChangeFileUpload(e.target.files[0])}
                      />

                      {!isEmpty(formik.values.file) && (
                        <MdDeleteForever
                          fontSize={"40px"}
                          onClick={() => {
                            setInputKey(randomString);
                            formik.setFieldValue("file", {});
                          }}
                          color={"red"}
                          cursor={"pointer"}
                        />
                      )}
                    </Flex>
                  </FormControl>
                </Flex>
              </Card>

              <Card
                direction="column"
                w="100%"
                px={5}
                overflowX={{ sm: "scroll", lg: "hidden" }}
                borderRadius="5px"
              >
                <Flex alignItems={"center"} direction={"column"} gap={5}>
                  <Checkbox
                    colorScheme="green"
                    isChecked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  >
                    I agree to Transfi Pay
                  </Checkbox>
                  <Button
                    variant="brand"
                    isDisabled={!isChecked}
                    onClick={formik.handleSubmit}
                  >
                    Generate and Send Payment Link
                  </Button>
                </Flex>
              </Card>
            </Flex>
          </chakra.form>
        </>
      )}
      <ShowBillLink
        isBillOpen={isLinkModalOpen}
        onBillClose={onLinkModalClose}
        linkContent={paymentLink}
      />
    </Box>
  );
};

export default GenerateBill;
