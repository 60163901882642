import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import "./assets/css/App.css";
// import { AppCoreContext } from "./context";
import "assets/css/App.css";
import App from "./App";
import theme from "./theme/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId="541543929438-71bds50sgg1brfsloo963o3h977jg6mv.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
