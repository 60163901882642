import {
  Box,
  Divider,
  Flex,
  Grid,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import useBillDetailStore from "store/bill";

const ViewBill = () => {
  const [billData, setBillData] = useState();
  const { getBillAction } = useBillDetailStore((state) => state);
  useEffect(() => {
    const billId = sessionStorage.getItem("billId");
    const userId = sessionStorage.getItem("userId");

    const loadBillData = async () => {
      const responseData = await getBillAction({ billId, userId });
      setBillData(responseData);
    };
    loadBillData();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 2.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        {billData && (
          <>
            <Card>
              <Flex gap="5" direction={"column"}>
                <Box>
                  <Text fontSize="sm">Bill Number</Text>
                  <Text fontWeight="bold" fontSize="xl" mt="10px">
                    {billData?.billDetails?.billId}
                  </Text>
                  <Text fontSize={"sm"} color="orange.900" mt="5px">
                    Pending
                  </Text>
                  <Text fontSize={"sm"} mt="5px">
                    {billData?.billDetails?.createdAt}
                  </Text>
                </Box>
                <Divider borderColor={"brand.700"} />
                <Box>
                  <Text fontSize="sm">Total Invoice Amount</Text>
                  <Text fontWeight="bold" fontSize="xl">
                    {billData?.billDetails?.invoiceAmount}{" "}
                    {billData?.billDetails?.invoiceCurrency}
                  </Text>
                  <Text fontSize={"sm"} mt="5px">
                    Payment Method
                  </Text>
                  <Text fontSize={"xl"} mt="5px">
                    Wire Transfer
                  </Text>
                </Box>
                <Divider borderColor={"brand.700"} />
                <Box>
                  <Text fontSize="sm">Buyer Email</Text>
                  <Text fontWeight="bold" fontSize="lg">
                    {billData?.buyerDetails?.email}
                  </Text>
                  <Text fontSize={"sm"} mt="5px">
                    Buyer Country
                  </Text>
                  <Text fontSize={"lg"} mt="5px">
                    {billData?.buyerDetails?.country}
                  </Text>
                </Box>
              </Flex>
            </Card>
            <Card>
              <Tabs>
                <TabList>
                  <Tab>Bill Details</Tab>
                  <Tab>Payment Method</Tab>
                  <Tab>Timeline</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <SimpleGrid columns={3} spacing={10}>
                      <Box>
                        <Text fontSize="sm">Total Invoice Amount</Text>
                        <Text fontSize="lg">
                          {billData?.billDetails?.invoiceAmount}{" "}
                          {billData?.billDetails?.invoiceCurrency}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="sm">Fees</Text>
                        <Text fontSize="md">Negotiated rates apply</Text>
                        <Text fontSize={"xs"}>
                          Refer to your agreement for applicable rates
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="sm">Net Settelment Amount</Text>
                        <Text fontSize="lg">
                          {billData?.billDetails?.invoiceAmount}{" "}
                          {billData?.billDetails?.invoiceCurrency}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="sm">Transaction Description</Text>
                        <Text fontSize="lg">
                          {billData?.billDetails?.txnDescription}
                        </Text>
                      </Box>
                    </SimpleGrid>
                  </TabPanel>
                  <TabPanel>
                    <SimpleGrid columns={3} spacing={10}>
                      <Box>
                        <Text fontSize={"sm"} mt="5px">
                          Payment Method
                        </Text>
                        <Text fontSize={"xl"} mt="5px">
                          Wire Transfer
                        </Text>
                      </Box>
                    </SimpleGrid>
                  </TabPanel>
                  <TabPanel>
                    <p>three!</p>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Card>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ViewBill;
