import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, Button } from "@chakra-ui/react";
import { ModalHeader, ModalFooter, ModalBody, Stack } from "@chakra-ui/react";
import { ModalCloseButton, FormControl, FormLabel } from "@chakra-ui/react";
import { FormErrorMessage, Input, Flex, SimpleGrid } from "@chakra-ui/react";
import { Select, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { chakra, RadioGroup, Radio, useToast } from "@chakra-ui/react";

import sc from "states-cities-db";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getJwtUser } from "utils/jwt-utils";
import useBuyerDetailStore from "store/buyer";

const AddUserDetail = (props) => {
  const { isOpen, onClose, loadData } = props;
  const [countryCode, setCountryCode] = useState("");
  const toast = useToast();
  const countriesList = sc.getCountries();

  const onCountrySelect = (value) => {
    const code = countriesList.filter((option) => option.iso2 === value);
    formik.setFieldValue("country", value);
    setCountryCode(code[0].prefix);
  };

  const { postBuyerDetailAction } = useBuyerDetailStore((state) => state);

  const onBuyerDetailSubmit = async (data) => {
    const userId = getJwtUser();
    data.type = "buyer";
    data.exporterUserId = userId;
    try {
      const responseData = await postBuyerDetailAction({ userId, data });
      if (responseData) {
        toast({
          title: "Buyer Details added Successfully",
          position: "top",
          status: "success",
          duration: 3000,
        });
        loadData();
        onClose();
      } else if (responseData && responseData?.message) {
        toast({
          title: responseData.message,
          position: "top",
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        position: "top",
        duration: 3000,
      });
    }
  };

  const validateFormSchema = Yup.object().shape({
    businessName: Yup.string().required("Please enter business name"),
    firstName: Yup.string()
      .required("First name is required")
      .min(3, "First Name is too short")
      .max(20, "First Name is too Long"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(1, "Last Name is too short")
      .max(20, "Last Name is too Long!"),
    email: Yup.string().required("Please enter Email"),
    country: Yup.string().required("Country is required"),
    contact: Yup.string()
      .matches("^[0-9]+$", "Contact number is not valid")
      .min(10, "Contact number is too short")
      .required("Contact number is required"),
    accountType: Yup.string().required("Account Type is required"),
    line1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    district: Yup.string().required("District is required"),
    postalCode: Yup.string().required("Postal code is required"),
  });

  const formik = useFormik({
    initialValues: {
      businessName: "",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      contact: "",
      accountType: "",
      line1: "",
      city: "",
      district: "",
      postalCode: "",
    },
    validationSchema: validateFormSchema,
    onSubmit: (data) => {
      if (data) onBuyerDetailSubmit(data);
    },
  });

  const onChangeType = (accountType) => {
    formik.setFieldValue("accountType", accountType);
  };

  const onChangeCountry = () => {};
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={"bold"}>Add New Business</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <chakra.form>
            <Flex direction={"column"} gap={3}>
              <FormControl
                isRequired
                isInvalid={
                  formik.errors.businessName && formik.touched.businessName
                }
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Company Name
                </FormLabel>
                <Input
                  type="text"
                  name="businessName"
                  placeholder="Business Name"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  onChange={formik.handleChange}
                  value={formik.values.businessName}
                />
                <FormErrorMessage>
                  {formik.errors.businessName}
                </FormErrorMessage>
              </FormControl>
              <SimpleGrid columns={2} gap={5}>
                <FormControl
                  isRequired
                  isInvalid={
                    formik.errors.firstName && formik.touched.firstName
                  }
                >
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={formik.touched.lastName && formik.errors.lastName}
                >
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={formik.errors.email && formik.touched.email}
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Email
                  </FormLabel>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    formik.touched.accountType && formik.errors.accountType
                  }
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Business Type
                  </FormLabel>
                  <RadioGroup onChange={onChangeType}>
                    <Stack direction="row">
                      <Radio value="business">Business</Radio>
                      <Radio value="individual">Individual</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={formik.errors.line1 && formik.touched.line1}
                >
                  <FormLabel>Address</FormLabel>
                  <Input
                    type="text"
                    name="line1"
                    placeholder="Enter your address"
                    value={formik.values.line1}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.line1}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={formik.errors.city && formik.touched.city}
                >
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    name="city"
                    placeholder="Enter city name"
                    value={formik.city}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={formik.errors.district && formik.touched.district}
                >
                  <FormLabel>State</FormLabel>
                  <Input
                    type="text"
                    name="district"
                    placeholder="Please enter state"
                    value={formik.district}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.district}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    formik.errors.postalCode && formik.touched.postalCode
                  }
                >
                  <FormLabel>Postal</FormLabel>
                  <Input
                    type="number"
                    name="postalCode"
                    placeholder="Enter your postal code"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    {formik.errors.postalCode}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={formik.errors.country && formik.touched.country}
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Country
                  </FormLabel>
                  <Select
                    name="country"
                    value={formik.values.country}
                    onChange={(e) => onCountrySelect(e.target.value)}
                    // onChange={(e) => onCountrySelect('select', e.target.value, { shouldValidate: true })}
                  >
                    <option value="" />
                    {countriesList.map((option) => (
                      <option value={option.iso2}>{option.name}</option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    formik.errors.postalCode && formik.touched.postalCode
                  }
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Contact Number
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      children={countryCode || "+91"}
                      width="auto"
                    />
                    <Input
                      type="tel"
                      placeholder="phone number"
                      name="contact"
                      value={formik.values.contact}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.contact}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            </Flex>
          </chakra.form>
        </ModalBody>

        <ModalFooter>
          <Button width={"30%"} variant="lightBrand" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button width={"30%"} variant="brand" onClick={formik.handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddUserDetail;
