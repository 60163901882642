import React, { useEffect } from "react";
import { startCase, isEmpty, unset } from "lodash";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import Select from "react-select";

import DateRangePicker from "../../../../components/calendar/DateRangePicker";

const PaymentsFilter = (props) => {
  const {
    isOpen,
    onClose,
    countryOptions,
    paymentOptions,
    setFilters,
    setDateRange,
    dateRange,
  } = props;
  const {
    formValues,
    setFormValues,
    inputData,
    setInputData,
    filters,
    handleOnReset,
  } = props;

  useEffect(() => {
    setFormValues(filters);
  }, []);

  const handleOnChanges = (key, op, value) => {
    setInputData({
      ...inputData,
      [key]: value,
    });
    let newValue;
    if (Array.isArray(value)) newValue = value.map((data) => data.value);
    else newValue = value.trim();
    setFormValues({
      ...formValues,
      [key]: { [`$${op}`]: newValue },
    });
  };

  const handleOnClear = () => {
    onClose();
    handleOnReset();
  };

  const handleOnFilter = () => {
    Object.keys(formValues).map((key) => {
      if (isEmpty(Object.values(formValues[key]))) unset(formValues, key);
    });
    setFilters(formValues);
    onClose();
  };

  const onChangeDateRange = (start, end) => {
    setDateRange({ startDate: start, endDate: end });
    setFormValues({
      ...formValues,
      ["dateRange"]: { startDate: start, endDate: end },
    });
  };

  const paymentMethods = [
    { label: startCase("cards"), value: "cards" },
    { label: startCase("internet_banking"), value: "internet_banking" },
    { label: startCase("local_bank_transfer"), value: "local_bank_transfer" },
    { label: startCase("mobile_money"), value: "mobile_money" },
    { label: startCase("real_Time_payments"), value: "real_Time_payments" },
    { label: startCase("voucher"), value: "voucher" },
    { label: startCase("wire"), value: "wire" },
  ];

  const statusOptions = [
    { label: startCase("cancelled"), value: "cancelled" },
    { label: startCase("partial_payment"), value: "partial_payment" },
    { label: startCase("failed"), value: "failed" },
    { label: startCase("partially_refunded"), value: "partially_refunded" },
    { label: startCase("incomplete"), value: "incomplete" },
    { label: startCase("payment_reported"), value: "payment_reported" },
    { label: startCase("refund_requested"), value: "refund_requested" },
    { label: startCase("pending"), value: "pending" },
    { label: startCase("refunded"), value: "refunded" },
    { label: startCase("succeeded"), value: "succeeded" },
    { label: startCase("risk_hold"), value: "risk_hold" },
  ];

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader style={{ color: "#422AFB", fontFamily: "arial" }}>
            Filters
          </DrawerHeader>
          <DrawerBody>
            <div style={{ margin: "12px 0" }}>
              <DateRangePicker
                width={"100%"}
                onChangeDateRange={onChangeDateRange}
                start={dateRange.startDate}
                end={dateRange.endDate}
              />
            </div>
            <div style={{ margin: "12px 0" }}>
              <Input
                placeholder="Buyer Email"
                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                type="text"
                value={formValues.buyer && Object.values(formValues.buyer)[0]}
                onChange={(e) => handleOnChanges("buyer", "eq", e.target.value)}
              />
            </div>
            <div style={{ margin: "12px 0" }}>
              <Select
                isMulti
                isSearchable={false}
                placeholder="Status"
                options={statusOptions}
                value={inputData.status}
                onChange={(e) => handleOnChanges("status", "in", e)}
              />
            </div>
            <div style={{ margin: "12px 0" }}>
              <Select
                isMulti
                isSearchable={false}
                placeholder="Payment Method"
                options={paymentMethods}
                value={inputData.paymentMethod}
                onChange={(e) => handleOnChanges("paymentMethod", "in", e)}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <Button
                onClick={() => handleOnFilter()}
                variant={"outline"}
                colorScheme="#422AFB"
                style={{ color: "#422AFB" }}
              >
                Filter
              </Button>
              <Button
                onClick={() => handleOnClear()}
                variant={"outline"}
                colorScheme="red"
                style={{ marginLeft: "30px" }}
              >
                Clear
              </Button>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PaymentsFilter;
