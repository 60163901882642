import create from "zustand";
import produce from "immer";
import { getProfile, postProfile } from "../../service/sign-in-service";

const INITIAL_PROFILE_STATE = {
  get: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
  post: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useProfileStore = create((set, get) => ({
  profileState: INITIAL_PROFILE_STATE,
  getProfileAction: async ({ userId }) => {
    set(
      produce((state) => {
        state.profileState.get.loading = true;
        state.profileState.post.loading = true;
      })
    );

    try {
      const data = await getProfile({ userId });
      set(
        produce((state) => {
          state.profileState.get.loading = false;
          state.profileState.get.success.ok = true;
          state.profileState.get.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.profileState.post.loading = false;
          state.profileState.post.failure.error = true;
          state.profileState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
  postProfieAction: async ({ userId, data: profileData }) => {
    set(
      produce((state) => {
        state.profileState.post.loading = true;
      })
    );

    try {
      const data = await postProfile({ userId, profileData });
      set(
        produce((state) => {
          state.profileState.post.loading = false;
          state.profileState.post.success.ok = true;
          state.profileState.post.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.profileState.post.loading = false;
          state.profileState.post.failure.error = true;
          state.profileState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
}));

export default useProfileStore;
