import {
    chakra,
    Box,
    Heading,
    Text,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Button,
    useToast,
    HStack,
  } from "@chakra-ui/react";
  import Card from "components/card/Card";
  import React from "react";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import { AiOutlineClose } from "react-icons/ai";
  import { useHistory } from "react-router-dom";
  
  import useBankDetailStore from "store/bank";
  import { getJwtUser } from "utils/jwt-utils";

  
  const ViewBankDetails = (props) => {
    const { showViewBankDetails, selectedBank } = props;
    const { accountId = "", currency, bankName, accountName, accountNumber, bankCodes, country, routingNumber, _id } = selectedBank || {};
    const toast = useToast();
    const userId = getJwtUser();
    const { updateBankDetails } = useBankDetailStore(
      (state) => state
    );
  
    const bankAccountSubmitHandler = async ({
      accountId,
      currency,
      bankName,
      accountName,
      accountNumber,
      bankCodes,
      routingNumber,
      country,
    }) => {
      try {
        const responseData = await updateBankDetails({
          accountId,
          currency,
          bankName,
          accountName,
          accountNumber,
          bankCodes,
          routingNumber,
          userId,
          country,
          id: _id
        });
        if (responseData) {
          toast({
            title: "Bank details updated successfully",
            position: "top",
            status: "success",
            duration: 3000,
          });
          showViewBankDetails(false);
        } 
      } catch (e) {
        toast({
          title: e.message || "Something went wrong.",
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    };
  
    const formik = useFormik({
      initialValues: {
        accountId,
        currency,
        bankName,
        accountName,
        accountNumber,
        bankCodes,
        routingNumber,
        country,
      },
      validationSchema: Yup.object({
        accountId: Yup.string().required("Please add account ID"),
        currency: Yup.string().required("Please add currency"),
        bankName: Yup.string().required("Please add Bank Name"),
        accountName: Yup.string().required("Please add Account holder name"),
        accountNumber: Yup.string()
          .required("Please add Account number")
          .matches("^[0-9]+$", "Pleas enter numbers"),
        bankCodes: Yup.string().required("Please add Bank Code"),
        routingNumber: Yup.string().required("Please add Bank SWIFT / BIC code"),
        country: Yup.string().required("Plawse add Bank country"),
      }),
      onSubmit: ({
        accountId,
        currency,
        bankName,
        accountName,
        accountNumber,
        bankCodes,
        routingNumber,
        country,
      }) => {
        bankAccountSubmitHandler({
          accountId,
          currency,
          bankName,
          accountName,
          accountNumber,
          bankCodes,
          routingNumber,
          country,
        });
      },
    });
  
    return (
      <Box width={"80%"} m="auto" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card p={10}>
          <Box>
            <HStack justifyContent="space-between" alignItems="center" >
            <Heading color="#1a4fd6" fontSize="30px" mb="10px">
              View/Edit Bank
            </Heading>
            <Button onClick={() => showViewBankDetails(false)} size={"md"} rightIcon={<AiOutlineClose />}> </Button>
            </HStack>
            <Text mb="36px" ms="4px" fontWeight="400" fontSize="md">
              Edit your bank details
            </Text>
          </Box>
          <chakra.form onSubmit={formik.handleSubmit}>
            <Flex direction={"column"} gap={5}>
              <FormControl
                isRequired
                isInvalid={formik.errors.accountId && formik.touched.accountId}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Account ID
                </FormLabel>
                <Input
                  type="text"
                  name="accountId"
                  placeholder="UUID of the Bank account"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  value={formik.values.accountId || accountId}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.accountId}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={formik.errors.currency && formik.touched.currency}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Currency
                </FormLabel>
                <Input
                  type="text"
                  name="currency"
                  placeholder="Currency of the bank account, codes. e.g. USD"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  disabled={true}
                  fontWeight="500"
                  size="lg"
                  value={formik.values.currency || currency}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={formik.errors.bankName && formik.touched.bankName}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Bank Name
                </FormLabel>
                <Input
                  type="text"
                  name="bankName"
                  placeholder="Name of the bank, example 'JP Morgan Chase'"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  value={formik.values.bankName || bankName}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.bankName}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={
                  formik.errors.accountName && formik.touched.accountName
                }
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Account Name
                </FormLabel>
                <Input
                  type="text"
                  name="accountName"
                  placeholder="Legal Name of the Account Holder"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  value={formik.values.accountName || accountName}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.accountName}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={
                  formik.errors.accountNumber && formik.touched.accountNumber
                }
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Account Number
                </FormLabel>
                <Input
                  type="text"
                  name="accountNumber"
                  placeholder="User's bank account number"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  onChange={formik.handleChange}
                  value={formik.values.accountNumber || accountNumber}
                />
                <FormErrorMessage>{formik.errors.accountNumber}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={formik.errors.bankCodes && formik.touched.bankCodes}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Bank Codes
                </FormLabel>
                <Input
                  type="text"
                  name="bankCodes"
                  placeholder="Bank codes"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  onChange={formik.handleChange}
                  value={formik.values.bankCodes || bankCodes}
                />
                <FormErrorMessage>{formik.errors.bankCodes}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={
                  formik.errors.routingNumber && formik.touched.routingNumber
                }
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  SWIFT / BIC code.
                </FormLabel>
                <Input
                  type="text"
                  name="routingNumber"
                  placeholder="The bank's SWIFT / BIC code."
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  onChange={formik.handleChange}
                  value={formik.values.routingNumber || routingNumber}
                />
                <FormErrorMessage>{formik.errors.routingNumber}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={formik.errors.country && formik.touched.country}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Bank Country
                </FormLabel>
                <Input
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  type="text"
                  name="country"
                  disabled={true}
                  placeholder="Bank Country"
                  onChange={formik.handleChange}
                  value={formik.values.country || country}
                />
                <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
              </FormControl>
              <Button
                variant="darkBrand"
                height={"50px"}
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
            </Flex>
          </chakra.form>
        </Card>
      </Box>
    );
  };
  
  export default ViewBankDetails;
  