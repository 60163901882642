import React, { useEffect } from "react";
import { startCase, pick } from "lodash";
import { FaUserEdit } from "react-icons/fa";
import sc from "states-cities-db";
import { Table, Tbody, Tr, Td, Button, TableContainer } from "@chakra-ui/react";
import { Text, Box, useDisclosure, Spinner, Center } from "@chakra-ui/react";

import { getJwtUser } from "utils/jwt-utils";
import useProfileStore from "store/profile";
import ProfileModal from "./EditAccount";

const AccountDetails = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userId = getJwtUser();
  const { profileState, getProfileAction } = useProfileStore((state) => state);
  const countriesList = sc.getCountries();

  const getProfile = () => {
    getProfileAction({ userId });
  };

  useEffect(() => {
    userId && getProfile();
  }, []);

  const data = profileState?.get?.success?.data || {};
  const profileData = pick(data, [
    "userId",
    "firstName",
    "lastName",
    "email",
    "contact",
    "contactCode",
    "accountType",
    "line1",
    "city",
    "district",
    "country",
    "postalCode",
  ]);

  const country = countriesList.filter(
    (option) => option.iso2 === data?.country
  );

  return (
    <>
      <Box mt={2}>
        <Button
          variant="darkBrand"
          float={"right"}
          leftIcon={<FaUserEdit />}
          onClick={onOpen}
        >
          Edit
        </Button>
        <Text
          color={"#805AD5"}
          mb={4}
          fontSize="xl"
          fontWeight={600}
          letterSpacing={0.1}
        >
          Personal Details
        </Text>

        {profileState?.get?.loading ? (
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        ) : (
          <TableContainer>
            <Table size="md" fontWeight={"500"} letterSpacing={0.1}>
              <Tbody>
                {Object.keys(profileData).map((key, idx) => {
                  const value =
                    key === "country"
                      ? `${country[0]?.name} ${profileData[key]}`
                      : profileData[key];
                  return (
                    <Tr key={idx}>
                      <Td fontWeight={"500"} borderColor={"#E2E8F0"}>
                        {startCase(key === "line1" ? "Address" : key)}
                      </Td>
                      <Td borderColor={"#E2E8F0"}>
                        <Text mt={1}>{value}</Text>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <ProfileModal
        isOpen={isOpen}
        onClose={onClose}
        profileData={profileData}
        userId={data.userId}
        getProfile={getProfile}
      />
    </>
  );
};

export default AccountDetails;
