import create from "zustand";
import produce from "immer";

import { postBuyerDetails } from "service/buyer-service";

const INITIAL_BUYER_DETAIL_STATE = {
  post: {
    loading: false,
    email: "",
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useBuyerDetailStore = create((set, get) => ({
  buyerDetailState: INITIAL_BUYER_DETAIL_STATE,
  postBuyerDetailAction: async ({ userId, data: buyerData }) => {
    set(
      produce((state) => {
        state.buyerDetailState.post.loading = true;
        state.buyerDetailState.post.success.ok = false;
      })
    );
    try {
      const data = await postBuyerDetails({ userId, buyerData });
      set(
        produce((state) => {
          state.buyerDetailState.post.loading = false;
          state.buyerDetailState.post.success.ok = true;
          state.buyerDetailState.post.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.buyerDetailState.post.loading = false;
          state.buyerDetailState.post.failure.error = true;
          state.buyerDetailState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
}));

export default useBuyerDetailStore;
