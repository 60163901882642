export const urlMap = {
  "localhost:3000": {
    env: "local",
    server: "http://localhost:8080",
  },
  "localhost:3001": {
    env: "local",
    server: "http://localhost:8080",
  },
  "dev-dashboard.transfipay.com": {
    env: "development",
    server: "https://dev-pay-server.transfi.com",
  },
  "dashboard.transfipay.com": {
    env: "production",
    server: "https://pay-server.transfi.com",
  },
};
