import create from "zustand";
import produce from "immer";
import {
  postBillService,
  getBillService,
  getBillDetailService,
} from "service/bill-service";

const INITIAL_BILL_DETAIL_STATE = {
  get: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
  post: {
    loading: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const useBillDetailStore = create((set, get) => ({
  billDetailState: INITIAL_BILL_DETAIL_STATE,
  postBillDetailAction: async ({ data: billData, userId }) => {
    set(
      produce((state) => {
        state.billDetailState.post.loading = true;
        state.billDetailState.post.success.ok = false;
      })
    );
    try {
      const data = await postBillService({ billData, userId });
      set(
        produce((state) => {
          state.billDetailState.post.loading = false;
          state.billDetailState.post.success.ok = true;
          state.billDetailState.post.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.billDetailState.post.loading = false;
          state.billDetailState.post.failure.error = true;
          state.billDetailState.post.failure.message = e.message;
        })
      );
      throw e;
    }
  },
  getBillAction: async ({ billId, userId }) => {
    set(
      produce((state) => {
        state.billDetailState.get.loading = true;
        state.billDetailState.get.success.ok = false;
      })
    );
    try {
      const data = await getBillDetailService({ billId, userId });
      console.log(data);
      set(
        produce((state) => {
          state.billDetailState.get.loading = false;
          state.billDetailState.get.success.ok = true;
          state.billDetailState.get.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.billDetailState.get.loading = false;
          state.billDetailState.get.failure.error = true;
          state.billDetailState.get.failure.message = e.message;
        })
      );
      throw e;
    }
  },
  getBillDetailAction: async ({ userId }) => {
    set(
      produce((state) => {
        state.billDetailState.get.loading = true;
        state.billDetailState.get.success.ok = false;
      })
    );
    try {
      const data = await getBillService({ userId });
      set(
        produce((state) => {
          state.billDetailState.get.loading = false;
          state.billDetailState.get.success.ok = true;
          state.billDetailState.get.success.data = data;
        })
      );
      return data;
    } catch (e) {
      set(
        produce((state) => {
          state.billDetailState.get.loading = false;
          state.billDetailState.get.failure.error = true;
          state.billDetailState.get.failure.message = e.message;
        })
      );
      throw e;
    }
  },
}));

export default useBillDetailStore;
