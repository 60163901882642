import * as Yup from "yup";

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(3, "First Name is too short")
    .max(20, "First Name is too Long"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(1, "Last Name is too short")
    .max(20, "Last Name is too Long!"),
  country: Yup.string().required("Country is required"),
  contact: Yup.string()
    .matches("^[0-9]+$", "Contact number is not valid")
    .required("Contact number is required"),
  accountType: Yup.string().required("Account Type is required"),
  line1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  district: Yup.string().required("District is required"),
  postalCode: Yup.string().required("Postal code is required"),
});
