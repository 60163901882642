import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";

const ReportsTable = () => {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex></Flex>
    </Card>
  );
};

export default ReportsTable;
