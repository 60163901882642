import React from "react";
import { Flex, FormControl, FormLabel, RadioGroup } from "@chakra-ui/react";
import { Input, Stack, Text, Radio, Box } from "@chakra-ui/react";
import { chakra, Button, useToast, FormErrorMessage } from "@chakra-ui/react";
import { InputGroup, InputLeftAddon, Select } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import sc from "states-cities-db";

import useProfileStore from "../../../store/profile";
import DefaultAuth from "../../../layouts/auth/Default";
import illustration from "../../../assets/img/auth/main-bg.png";
import { getJwtUser } from "utils/jwt-utils";
import { profileValidationSchema } from "../../admin/account/utils";

const Profile = () => {
  const userId = getJwtUser();
  const { profileState, postProfieAction } = useProfileStore((state) => state);
  const history = useHistory();
  const toast = useToast();

  const countriesList = sc.getCountries();
  const onCountrySelect = (value) => {
    const code = countriesList.filter((option) => option.iso2 === value);
    formik.setFieldValue("country", value);
    formik.setFieldValue("contactCode", code[0].prefix);
  };

  const onSubmitHandler = async (data) => {
    try {
      const responseData = await postProfieAction({ userId, data });
      if (responseData) {
        toast({
          title: "Profile added Successfully!!",
          position: "top",
          status: "success",
          duration: 3000,
        });
        setTimeout(() => {
          formik.accountType === "individual"
            ? history.push("/admin/default", { replace: true })
            : history.push("/auth/business", { replace: true });
        }, 1000);
      } else {
        toast({
          title: "Failed to update data",
          position: "top",
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: e.message || "Something went wrong.",
        status: "error",
        position: "top",
        duration: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      contact: "",
      contactCode: "",
      accountType: "",
      line1: "",
      city: "",
      district: "",
      postalCode: "",
      type: "seller",
    },
    validationSchema: profileValidationSchema,
    onSubmit: (data) => {
      if (data) onSubmitHandler(data);
    },
  });

  const onChangeType = (accountType) => {
    formik.setFieldValue("accountType", accountType);
  };

  const formLabelStyles = {
    display: "flex",
    ms: "4px",
    fontSize: "sm",
    fontWeight: "500",
    mb: "7px",
  };

  const formInputStyles = {
    fontSize: "md",
    fontWeight: "500",
    size: "lg",
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "30px" }}
        mt={{ base: "25px", md: "7vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Text
            color="#1a4fd6"
            fontSize="26px"
            mb="10px"
            letterSpacing={0.1}
            fontWeight={600}
          >
            Profile Details
          </Text>
          <Text
            mb="36px"
            ms="4px"
            fontWeight="500"
            fontSize="md"
            letterSpacing={0.1}
          >
            Enter your Personal details
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "15px", md: "auto" }}
        >
          <chakra.form>
            <Flex direction="column" gap={4}>
              <FormControl
                isRequired
                isInvalid={formik.touched.firstName && formik.errors.firstName}
              >
                <FormLabel {...formLabelStyles}>First Name</FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  {...formInputStyles}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.lastName && formik.errors.lastName}
              >
                <FormLabel {...formLabelStyles}>Last Name</FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  {...formInputStyles}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.country && formik.errors.country}
              >
                <FormLabel {...formLabelStyles}>Country</FormLabel>
                <Select
                  name="country"
                  value={formik.values.country}
                  placeholder="Select your country"
                  onChange={(e) => onCountrySelect(e.target.value)}
                  {...formInputStyles}
                >
                  {countriesList.map((option) => (
                    <option value={option.iso2}>{option.name}</option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.contact && formik.errors.contact}
              >
                <FormLabel {...formLabelStyles}>Contact</FormLabel>
                <InputGroup {...formInputStyles}>
                  <InputLeftAddon
                    children={formik.values.contactCode}
                    width="auto"
                    minW={"90px"}
                  />
                  <Input
                    type="tel"
                    placeholder="Phone number"
                    name="contact"
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                    {...formInputStyles}
                  />
                </InputGroup>
                <FormErrorMessage>{formik.errors.contact}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={
                  formik.touched.accountType && formik.errors.accountType
                }
              >
                <FormLabel {...formLabelStyles}>Type</FormLabel>
                <RadioGroup onChange={onChangeType} {...formInputStyles}>
                  <Stack direction="row">
                    <Radio value="business">Business</Radio>
                    <Radio value="individual">Individual</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.city && formik.errors.city}
              >
                <FormLabel {...formLabelStyles}>City</FormLabel>
                <Input
                  type="text"
                  name="city"
                  placeholder="Enter your city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  {...formInputStyles}
                />
                <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.district && formik.errors.district}
              >
                <FormLabel {...formLabelStyles}>District</FormLabel>
                <Input
                  type="text"
                  name="district"
                  placeholder="Enter your district"
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  {...formInputStyles}
                />
                <FormErrorMessage>{formik.errors.district}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.line1 && formik.errors.line1}
              >
                <FormLabel {...formLabelStyles}>Address</FormLabel>
                <Input
                  type="address"
                  name="line1"
                  placeholder="Enter your address"
                  value={formik.values.line1}
                  onChange={formik.handleChange}
                  {...formInputStyles}
                />
                <FormErrorMessage>{formik.errors.line1}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={
                  formik.touched.postalCode && formik.errors.postalCode
                }
              >
                <FormLabel {...formLabelStyles}>Postal Code</FormLabel>
                <Input
                  type="number"
                  name="postalCode"
                  placeholder="Enter your postal code"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  {...formInputStyles}
                />
                <FormErrorMessage>{formik.errors.postalCode}</FormErrorMessage>
              </FormControl>

              <Button
                fontSize="sm"
                variant="solid"
                colorScheme="blue"
                fontWeight="500"
                w="100%"
                my="8%"
                isLoading={profileState.post.loading}
                isDisabled={profileState.post.loading}
                onClick={formik.handleSubmit}
              >
                Save and Next
              </Button>
            </Flex>
          </chakra.form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default Profile;
