import React, { useEffect } from "react";
import { startCase, pick, isEmpty } from "lodash";
import sc from "states-cities-db";
import { Table, Tbody, Tr, Td, TableContainer } from "@chakra-ui/react";
import { Text, Box, Spinner, Center } from "@chakra-ui/react";

import { getJwtUser } from "utils/jwt-utils";
import useBusinessDetailStore from "store/business";
import BusinessForm from "routes/auth/business/components/BusinessForm";

const AccountDetails = () => {
  const userId = getJwtUser();
  const { businessDetailState, getBusinessDetailAction } =
    useBusinessDetailStore((state) => state);
  const countriesList = sc.getCountries();

  const getBusiness = () => {
    getBusinessDetailAction(userId);
  };

  useEffect(() => {
    userId && getBusiness();
  }, []);

  const data = businessDetailState?.get?.success?.data || {};
  const businessData = pick(data, [
    "name",
    "incorporationNo",
    "city",
    "state",
    "country",
    "zipCode",
    "createdBy",
  ]);

  const country = countriesList.filter(
    (option) => option.iso2 === data?.country
  );

  const ViewBusiness = () => {
    return (
      <TableContainer>
        <Table size="md" fontWeight={"500"} letterSpacing={0.1}>
          <Tbody>
            {Object.keys(businessData).map((key, idx) => {
              const value =
                key === "country"
                  ? `${country[0]?.name} ${businessData[key]}`
                  : businessData[key];
              return (
                <Tr key={idx}>
                  <Td fontWeight={"500"} borderColor={"#E2E8F0"}>
                    {startCase(key === "line1" ? "Address" : key)}
                  </Td>
                  <Td borderColor={"#E2E8F0"}>
                    <Text mt={1}>{isEmpty(value) ? "NA" : value}</Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Box mt={2}>
        <Text
          color={"#805AD5"}
          mb={4}
          fontSize="xl"
          fontWeight={600}
          letterSpacing={0.1}
        >
          Business Details
        </Text>

        {businessDetailState?.get?.loading ? (
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        ) : !isEmpty(businessData) ? (
          <ViewBusiness />
        ) : (
          <BusinessForm />
        )}
      </Box>
    </>
  );
};

export default AccountDetails;
