import { getOrgId } from '../components/App/useToken';
import { urlMap } from './config';
import { getJwtUser } from './jwt-utils';
import { getDataFromSession } from './session-storing-utils';

const methodListBody = ["POST", "PUT"];

const callApi = async (method, URL, body) => {
  return fetch(`${urlMap[window.location.host].server}${URL}`, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  }).then(data =>
    data.json()
  );
};

const callApiWithToken = async (method, URL, body = {}) => {
  const tokens = localStorage.getItem('auth-tokens-development') === null ? localStorage.getItem('auth-tokens-production') : localStorage.getItem('auth-tokens-development');
  const { accessToken: token } = JSON.parse(tokens);
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  const userId = getJwtUser();
  const formattedBody = { ...body, userId }

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  headers.append("envType", getDataFromSession('connectionData', 'envType'))

  const options = {
    method,
    headers: headers,
  };

  if (methodListBody.includes(method)) {
    options["body"] = JSON.stringify(formattedBody);
  }

  const resp = await fetch(`${urlMap[window.location.host].server}${URL}`, options)
    .then(response => {
      if (response.status === 401) {
        window.localStorage.clear();
        window.location.reload();
      }
      return response.json()
    })
    .then(response => {
      return response
    }).catch(error => {
      return error
    });

  return resp;
}

const callApiWithTokenMultipart = async (method, URL, formData = {}) => {
  try {
    const tokens = localStorage.getItem('auth-tokens-development') === null ? localStorage.getItem('auth-tokens-production') : localStorage.getItem('auth-tokens-development');
    const { accessToken: token } = JSON.parse(tokens);
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const orgId = getOrgId();

    if (formData && formData instanceof FormData) {
      formData.append("orgId", orgId);
    } else {
      throw "Invalid input";
    }

    headers.append("Authorization", bearer);
    headers.append("envType", getDataFromSession('connectionData', 'envType'))

    const options = {
      method,
      headers: headers,
    };

    if (methodListBody.includes(method)) {
      options["body"] = formData;
    }

    const resp = await fetch(`${urlMap[window.location.host].server}${URL}`, options)
      .then(response => {
        if (response.status === 401) {
          window.sessionStorage.clear();
          window.location.reload();
        }
        return response.json()
      })
      .then(response => {
        return response
      }).catch(error => {
        throw error;
      });

    return resp;

  } catch (error) {
    return error;
  }
}

export {
  callApi,
  callApiWithToken,
  callApiWithTokenMultipart
};